import React, { useEffect, useState } from "react";
import { FormLabel, FormControl as Input } from "react-bootstrap";
import { RiArrowGoBackLine } from "react-icons/ri";
import DefaultButton from "components/DefaultButton";
import ImageInput from "components/Inputs/ImageInput";
import FileInput from "components/Inputs/FileInput";
import { Get } from "utils/request";
import { useParams } from "react-router";
import { STORAGE_URL } from "variables";
import InputFile from "components/Inputs/InputFile";

const FormBody = props => {

    const {
        loading,
        OnConfirm
    } = props;

    const [title, SetTitle] = useState("");
    const [description, SetDescription] = useState("");
    const [file, SetFile] = useState("");
    const [filePath, SetFilePath] = useState("");
    const [fileName, SetFileName] = useState("");

    const [existingDoc, SetExistingDoc] = useState([]);

    const { post_id } = useParams();

    const GetData = async () => {
        let response = await Get(`site-itec/posts?id=${post_id}`);
        if (response?.status === true) {
            SetTitle(response?.post?.title);
            SetDescription(response?.post?.description);
            SetFile(response?.post?.file ? response?.post?.file : "");
            SetFileName(response?.post?.file_name);
            if (response?.post?.file != "undefined" && response?.post?.file != "") SetExistingDoc([{ path: response?.post?.file, name: response?.post?.file_name }]);
        }
    }

    useEffect(() => GetData(), []);

    return(
        <div className="form-course">
            <form>
                <FormLabel>Título <span style={{color: "red"}}>*</span></FormLabel>
                <Input
                    placeholder="Nome"
                    value={title}
                    onChange={e => SetTitle(e.target.value)}
                    required
                />
                <br/>

                <FormLabel>Descrição</FormLabel>
                <Input
                    placeholder="Descrição"
                    value={description}
                    onChange={e => SetDescription(e.target.value)}
                    as="textarea"
                    required
                />
                <br/>

                <FormLabel>Arquivo</FormLabel>
                <FileInput
                    OnChange={value => SetFile(value)}
                    accept=".pdf"
                    existingDocs={existingDoc}
                    HandleExistingDocDeletion={e => {
                        // SetFilePath("");
                        SetFile("");
                        SetExistingDoc([]);
                    }}
                    single
                />
                <br/>
                {/* <InputFile
                    paths={[filePath]}
                /> */}

                <FormLabel>Nome do arquivo</FormLabel>
                <Input
                    placeholder="Nome do arquivo"
                    value={fileName}
                    onChange={e => SetFileName(e.target.value)}
                />

            </form>
            <br/>
            <div className="w100 inline-flex jcsb mt-2">
                <DefaultButton
                    bg="secondary"
                    text="Voltar"
                    icon={<RiArrowGoBackLine />}
                    to="/site-itec/publicacoes"
                />
                <DefaultButton
                    bg="confirm"
                    text="Salvar publicação"
                    onClick={() => {
                        let data = {
                            title: title,
                            description: description,
                            file: file,
                            fileName: fileName
                        };
                        OnConfirm(data);
                    }}
                    loading={loading}
                />
            </div>
        </div>
    );
}

export default FormBody;