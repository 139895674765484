import React, { useState } from "react";

// import { TextField } from "@material-ui/core";
import { FormControl as Input } from "react-bootstrap";

import Table from "./Table";
import DefaultButton from "../../../components/DefaultButton";
import RoleBased from "components/Role/RoleBased";

const AccountsLog = () => {

    const [search, SetSearch] = useState("");

    return (
        <div className="card">
            <div className="card-body">
                <h2 className="title">Lista de contas</h2>
                <p className="text-muted">Aqui são listados todas as contas cadastradas no sistema</p>
                <hr/>
                <div className="flex jcsb flex-wrap margin-bottom">
                    <Input
                        variant="filled"
                        placeholder="Pesquisar conta por Nome ou E-mail"
                        // className="flex1"
                        value={search}
                        onChange={e => SetSearch(e.target.value)}
                        style={{ width: "50%" }}
                    />
                    <RoleBased>
                        <DefaultButton
                            bg="confirm"
                            text="Adicionar nova conta"
                            to="/accounts/create"
                        />
                    </RoleBased>
                </div>
                <Table
                    search={search}
                />
            </div>
        </div>
    );
}

export default AccountsLog;