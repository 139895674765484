import React, { useState, useRef, useEffect } from "react";

import { Card as MaterialCard, CardContent, CardMedia, Popover } from "@material-ui/core";

import placeholder from "../../../../assets/images/logo.png";
import { STORAGE_URL } from "../../../../variables";

import styles from "./index.module.css";
import CardModal from "./CardModal";
import ExpandableModal from "./ExpandableModal";

const Card = props => {

    const {
        id,
        image,
        name,
        description,
        listed,
        lessons,
        GetData = () => {}
    } = props;

    const [anchor, SetAnchor] = useState(null);
    const [open, SetOpen] = useState(false);

    const [expandableModal, SetExpandableModal] = useState(false);

    const isHover = useRef(false);
    const timer = useRef();

    const HandlePopoverOpen = e => {
        SetAnchor(e.currentTarget);
        timer.current = setTimeout(() => {
            (!open) && SetOpen(true);
        }, 125);
        isHover.current = true;
        return () => clearTimeout(timer.current);
    }

    const HandlePopoverClose = () => {
        isHover.current = false;
        SetOpen(false);
    }

    useEffect(() => {
        document.addEventListener("wheel", HandlePopoverClose);
        return () => {
            document.removeEventListener("wheel", HandlePopoverClose);
        };
    }, []);

    useEffect(() => {
        if (expandableModal) document.body.style.overflow = "hidden";
        else document.body.style.overflow = "initial";
    }, [expandableModal])

    return (
        <div className={styles.cardContainer}>
            <MaterialCard
                className={styles.card}
                onMouseOver={HandlePopoverOpen}
                id={open ? "simple-popover" : undefined}
            >
                <CardMedia
                    component="img"
                    height="200"
                    image={image ? STORAGE_URL + image : placeholder}
                    alt="Curso card"
                />
            </MaterialCard>
            <CardModal
                open={open}
                anchor={anchor}
                id={id}
                image={image}
                listed={listed}
                name={name}
                description={description}
                lessons={lessons}
                GetData={GetData}
                HandlePopoverClose={HandlePopoverClose}
                OnExpand={() => SetExpandableModal(true)}
                className={styles.cardModal}
            />
            
            <ExpandableModal
                lessons={lessons}
                image={image}
                open={expandableModal}
                description={description}
                OnClose={() => SetExpandableModal(false)}
                scroll={"body"}
            />
        </div>
    );
}

export default Card;