import React from "react";
import NoAuth from "./NoAuth";

import "./index.css";
import { useSelector } from "react-redux";
import SocialMedias from "../../components/SocialMedias";

const Footer = () => {
    
    const generalStyle = useSelector(store => store.AppReducer.generalStyle);

    return (
        <div 
            className="footer"
            style={{
                ...(generalStyle && {color: generalStyle?.footer_font_color}),
                ...(generalStyle && {backgroundColor: generalStyle?.footer_background_color})
            }}
        >
            {/* <NoAuth/> */}
            <div className="contact-data">
                <h6 className="font-weight-bold">Entre em contato</h6>
                <p 
                    hidden={!generalStyle.phone_number}
                >Fone: {generalStyle?.phone_number}</p>
                <p
                    hidden={!generalStyle?.email}
                >E-mail: {generalStyle?.email}</p>
                <p
                    hidden={!generalStyle.whatsapp_number}
                >Whatsapp: {generalStyle?.whatsapp_number}</p>
            </div>
            <div id="copyright" className="w100 flex jcc text-center fdcolumn">
                <SocialMedias className="jcc"/>
                <span className="text-muted">
                    Direitos reservados FACULDADE ITEC<br/>
                    CNPJ 32.423.690/0001-88
                </span>
            </div>
        </div>
    );
}

export default Footer;