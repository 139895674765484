import { useState } from "react";
import { Collapse } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import styles from "./index.module.css";

const CollapseContainer = props => {

    const {
        title,
        children,
        ...other
    } = props;

    const [expanded, SetExpanded] = useState(false);

    return (
        <div className="collapseContainer">
            <div className={`flex w100 jcsb align-center ${styles.expandTab}`}>
                <span className={styles.title}>{title}</span>
                <span 
                    onClick={() => SetExpanded(!expanded)}
                    className="flex fdrow"
                    style={{
                        userSelect: "none",
                        cursor: "pointer"
                    }}
                >
                    <div className={styles.button}>
                        {!expanded ? "Mostrar mais" : "Mostrar menos"}
                    </div>
                    <ExpandMoreIcon
                        className={`${expanded ? styles.expandedIcon : ""} ${styles.icon}`}
                    />
                </span>
            </div>
            <Collapse in={expanded}>
                {children}
            </Collapse>
        </div>
    );
}

export default CollapseContainer;