import { CircularProgress, IconButton, Slider } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import styles from "./index.module.css";

import Controls from "./Controls";
import PlayingOverlay from "./PlayingOverlay";
//TODO adicionar um timer para mandar o tempo que o tempo em que o vídeo está
const Video = props => {

    const {
        src = "",
        srcList = [],
        next = "",
        prev = "",
        hidden,
        onPrev = () => {},
        onNext = () => {},
        onTimeUpdate = () => {},
        ...other
    } = props;

    // const [slider, SetSlider] = useState(0);
    const [endTime, SetEndTime] = useState(0);
    const [currTime, SetCurrTime] = useState(0);
    // const [volume, SetVolume] = useState(50);
    const [playing, SetPlaying] = useState(false);
    const [fullscreen, SetFullscreen] = useState(false);

    const player = useRef();
    const container = useRef();
    const timer = useRef();

    useEffect(() => {
        if (player?.current === undefined) return;

        if (playing) player.current.play();
        else player.current.pause();
    }, [playing]);

    useEffect(() => {
        if (fullscreen) {
            if (container?.current.requestFullscreen) container.current.requestFullscreen().catch(err => Promise.resolve(err));
            else if (container?.current.msRequestFullscreen) container.current.msRequestFullscreen().catch(err => Promise.resolve(err));
            else if (container?.current.mozRequestFullScreen) container.current.mozRequestFullScreen().catch(err => Promise.resolve(err));
            else if (container?.current.webkitRequestFullScreen) container.current.webkitRequestFullScreen().catch(err => Promise.resolve(err));
        } else {
            if (document.exitFullscreen) document.exitFullscreen().catch(err => Promise.resolve(err));
            else if (document.msExitFullscreen) document.msExitFullScreen().catch(err => Promise.resolve(err));
            else if (document.mozExitFullscreen) document.mozExitFullScreen().catch(err => Promise.resolve(err));
            else if (document.webkitExitFullscreen) document.webkitExitFullScreen().catch(err => Promise.resolve(err));
        }
    }, [fullscreen]);

    useEffect(() => player?.current && (player.current.src = src), [src]);

    useEffect(() => {
        timer.current = setInterval(onTimeUpdate(player?.current?.currentTime), 1000);
        return () => clearInterval(timer.current);
    });

    return (
        <div className={styles.container} hidden={hidden} ref={container}>
            <PlayingOverlay
                playing={playing}
            />
            <video
                {...other}
                ref={player}
                className={styles.player}
                onMouseUp={() => SetPlaying(!playing)}
                // onTouchStart={() => SetPlaying(!playing)}//mostrar os controles para pausar e etc
                // onTouchEnd={e => e.preventDefault()}
                onDoubleClick={() => SetFullscreen(!fullscreen)}
                onTimeUpdate={e => SetCurrTime(e.target.currentTime)}
            />
            <Controls
                ref={{
                    player: player,
                    container: container
                }}
                prev={prev}
                next={next}
                setPlaying={playing}
                onPrev={onPrev}
                onNext={onNext}
                onEndTime={value => SetEndTime(value)}
                onPlayingChange={value => SetPlaying(value)}
            />
            <div className={styles.loading}>
                <CircularProgress
                    hidden={player?.current?.readyState === 4 || player?.current?.currentTime == endTime}
                    style={{
                        color: "white"
                    }}
                />
            </div>
        </div>
    );
}

export default Video;