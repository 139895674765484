import React, { useEffect, useState } from 'react';
import { FormLabel, FormControl as Input } from "react-bootstrap";
import DefaultButton from 'components/DefaultButton';
import Label from 'components/InfoLabel';
import { Get } from 'utils/request';

import { config } from "./jodit-config";
import JoditEditor from "jodit-react"
import { PAGE_KEYS } from "variables";

const FormBody = props =>{

  const {
    OnConfirm,
    loading,
    type,
    pageName
  } = props;

  const [title, SetTitle] = useState("")
  const [contentHtml, SetContentHtml] = useState();

  const GetData = async () =>{
    let response = await Get(`site-itec/institutional?type=${type}`);
    if(response?.status === true){
      SetTitle(response?.page?.title);
      SetContentHtml(response?.page?.content);
    }
  }
  useEffect(()=> GetData(),[]);

  return(
    <div className="form-course">
      <form>
        <FormLabel>Título
        <span style={{color: "red"}}>*</span>
        </FormLabel>
        <Input
          placeholder="Título"
          value={title}
          onChange={e => SetTitle(e.target.value)}
          required
        />
        <br/>
        <FormLabel>Conteúdo da página {pageName} <span style={{color: "red"}}>*</span></FormLabel>
        <JoditEditor
            value={contentHtml}
            config={config}
            onChange={value => SetContentHtml(value)}
        />
      </form>
      <br/>
      <div className="w100 inline-flex jsend mt-2">
                <DefaultButton
                    bg="confirm"
                    text="Salvar alterações"
                    onClick={() => {
                        let data = {            
                            title: title,
                            contentHtml: contentHtml,
                            type: type,
                            
                        };
                        OnConfirm(data);
                    }}
                    loading={loading}
                />
            </div>
    </div>
  )

}
export default FormBody;