import { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Post } from "utils/request"
import { Show } from "actions/SnackbarActions"
import FormBody from "./FormBody";

const BannerAdsAdd = () => {
    const [popup, SetPopup] = useState(false);
    const [popupMessage, SetPopupMessage] = useState("");

    const [loading, SetLoading] = useState(false);

    const userId = useSelector(store => store.AppReducer.user?.id);

    const dispatch = useDispatch();
    const history = useHistory();

    const Submit = async (data) => {
        if (!data?.image) {
            SetPopup(true);
            SetPopupMessage("Insira uma imagem");
            return;
        }
        else if(!data?.title){
            SetPopup(true);
            SetPopupMessage("Insite um título");
            return
        }

        let form = new FormData();
        form.append("image", data.image);
        form.append("link", data.link);
        form.append("title", data.title);

        SetLoading(true);
        let response = await Post("site-itec/ads-banner-images/create", form);
        SetLoading(false);
        dispatch(Show({
            show: true,
            message: response?.message,
            severity: response?.severity
        }));

        if (response?.status === true) history.push("/site-itec/banner-home");
    }

    return (
        <div className="card">
            <SweetAlert
                warning
                show={popup}
                title="Atenção!"
                confirmBtnText="Ok"
                onConfirm={() => SetPopup(false)}
            >
                {popupMessage}
            </SweetAlert>
            <div className="card-body">
                <h2 className="title">Criar banner de propaganda</h2>
                <p>Aqui são criados os banners de propaganda do site do Itec</p>
                <br/>
                <FormBody
                    OnConfirm={data => Submit(data)}
                    loading={loading}
                />
            </div>
        </div>
    );
}

export default BannerAdsAdd;