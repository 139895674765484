import React, { useCallback } from 'react';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar';

import { FaTachometerAlt, FaGem, FaList, FaGithub, FaRegLaughWink, FaHeart, FaWineBottle, FaUserAlt, FaTruckMoving, FaBriefcase, FaFolderPlus, FaCog, FaChartLine, FaRegComments, FaRecycle } from 'react-icons/fa';
import { AiFillSetting } from "react-icons/ai";
import { GiRead } from "react-icons/gi";

import { ReactComponent as ItecSvg } from "../assets/svgs/favicon.svg";

import sidebarBg from './assets/bg1.jpg';
import Logo from '../assets/images/logo2.png';
import { Link, NavLink } from 'react-router-dom';
import { MdCopyright } from 'react-icons/md';
import { useSelector } from 'react-redux';
import RoleBased from 'components/Role/RoleBased';

const Aside = props => {

  const {
    image,
    collapsed,
    toggled,
    handleToggleSidebar,
    handleCollapsedChange = () => {},
    ...other
  } = props;

  // #084808
  let user = useSelector(store => store.AppReducer.user);
    

  return (
    <ProSidebar
      image={image ? sidebarBg : false}
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="md"
      style={{ position: 'fixed' }}
      onToggle={handleToggleSidebar}
      {...other}
    >
      <SidebarHeader style={{ background: '#222d32', height: 70 }}>
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: "center",
            alignItems: 'center'
          }}
        >
          {collapsed == true && <span>
            <img src={Logo} alt="" height='60' style={{
              height: '60px',
              width: '90%',
              objectFit: 'contain'
            }} />
          </span>}
          {collapsed == false && <i>
            <img className="img-fluid" src={Logo} alt="" height="100" style={{ height: '40px' }} />
          </i>}
        </div>
      </SidebarHeader>

      <SidebarContent style={{ background: '#222d32' }}>
        <Menu iconShape="circle">
          <MenuItem
            icon={<FaChartLine />}
          // suffix={<span className="badge red">{'Usuários'}</span>}
          >

            <NavLink exact to="/" activeStyle={{
              fontWeight: "bold",
              color: "white"
            }}>{'Dashboard'}</NavLink>
          </MenuItem>
          <RoleBased>
            <SubMenu icon={<FaGem />} title="Leads">
              <MenuItem>
                <NavLink 
                  to="/log/email" 
                  activeStyle={{
                  fontWeight: "bold",
                  color: "white"
                }}>
                  E-mails
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink 
                  to="/log/accounts" 
                  activeStyle={{
                  fontWeight: "bold",
                  color: "white"
                }}>
                  Contas
                </NavLink>
              </MenuItem>
            </SubMenu>
          </RoleBased>
          <RoleBased>
            <SubMenu icon={<FaBriefcase />} title="Vestibular">
              <MenuItem>
                <NavLink 
                  to="/cursos" 
                  activeStyle={{
                  fontWeight: "bold",
                  color: "white"
                }}>
                  Cursos
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink 
                  to="/polos" 
                  activeStyle={{
                  fontWeight: "bold",
                  color: "white"
                }}>
                  Polos
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink 
                  to="/inscricoes" 
                  activeStyle={{
                  fontWeight: "bold",
                  color: "white"
                }}>
                  Inscrições
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink 
                  to="/pre-inscricoes/lista" 
                  activeStyle={{
                  fontWeight: "bold",
                  color: "white"
                }}>
                  Pré-inscrições
                </NavLink>
              </MenuItem>
            </SubMenu>
            <SubMenu
              icon={<AiFillSetting/>}
              title="Páginas"
            >
                <MenuItem>
                  <NavLink 
                    to="/page/config/general" 
                    activeStyle={{
                    fontWeight: "bold",
                    color: "white"
                  }}>
                    Geral
                  </NavLink>
                </MenuItem>
                <MenuItem>
                  <NavLink 
                    to="/page/config/landing-page" 
                    activeStyle={{
                    fontWeight: "bold",
                    color: "white"
                  }}>
                    Página Inicial
                  </NavLink>
                </MenuItem>
                <MenuItem>
                  <NavLink 
                    to="/page/config/login" 
                    activeStyle={{
                    fontWeight: "bold",
                    color: "white"
                  }}>
                    Página de Login
                  </NavLink>
                </MenuItem>
                <MenuItem>
                  <NavLink 
                    to="/page/config/register" 
                    activeStyle={{
                    fontWeight: "bold",
                    color: "white"
                  }}>
                    Página de Cadastro
                  </NavLink>
                </MenuItem>
            </SubMenu>
          </RoleBased>
          {/* <MenuItem icon={<FaGem />}> {'Cadastros'}</MenuItem> */}
          <SubMenu
            icon={<GiRead />}
            title="Cursos"
          >
            <MenuItem>
              <NavLink 
                to="/courses/list" 
                activeStyle={{
                fontWeight: "bold",
                color: "white"
              }}>
                Todos os cursos
              </NavLink>
            </MenuItem>
            <RoleBased>
              <MenuItem>
                <NavLink 
                  to="/categorias" 
                  activeStyle={{
                  fontWeight: "bold",
                  color: "white"
                }}>
                  Categorias
                </NavLink>
              </MenuItem>
            </RoleBased>
            <RoleBased>
              <MenuItem>
                <NavLink 
                  to="/tag" 
                  activeStyle={{
                  fontWeight: "bold",
                  color: "white"
                }}>
                  Tags
                </NavLink>
              </MenuItem>
            </RoleBased>
          </SubMenu>
          
          <RoleBased>
            <SubMenu
              title="Site Itec"
              icon={<ItecSvg/>}
            >
              <SubMenu
              title="Institucional"
              >
                <MenuItem>
                  <NavLink 
                    to="/site-itec/institucional/historia" 
                    activeStyle={{
                    fontWeight: "bold",
                    color: "white"
                  }}>
                    História
                  </NavLink>
                </MenuItem>
                <MenuItem>
                  <NavLink 
                    to="/site-itec/institucional/missao" 
                    activeStyle={{
                    fontWeight: "bold",
                    color: "white"
                  }}>
                    Missão
                  </NavLink>
                </MenuItem>
                <MenuItem>
                  <NavLink 
                    to="/site-itec/institucional/visao" 
                    activeStyle={{
                    fontWeight: "bold",
                    color: "white"
                  }}>
                    Visão
                  </NavLink>
                </MenuItem>
                <MenuItem>
                  <NavLink 
                    to="/site-itec/institucional/valores" 
                    activeStyle={{
                    fontWeight: "bold",
                    color: "white"
                  }}>
                    Valores
                  </NavLink>
                </MenuItem>
              </SubMenu>
              <MenuItem>
                <NavLink 
                  to="/site-itec/noticias" 
                  activeStyle={{
                  fontWeight: "bold",
                  color: "white"
                }}>
                  Notícias
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                 to="/site-itec/bannerPrincipal-home"
                 activeStyle={{
                   fontWeight: "bold",
                   color: "white"
                  }}
                >
                  Banner Principal
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink 
                  to="/site-itec/banner-home" 
                  activeStyle={{
                  fontWeight: "bold",
                  color: "white",
                }}>
                  Banner Promoção
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink 
                  to="/site-itec/publicacoes" 
                  activeStyle={{
                  fontWeight: "bold",
                  color: "white"
                }}>
                  Publicações
                </NavLink>
              </MenuItem>
            </SubMenu>
          </RoleBased>
        </Menu>

      </SidebarContent>

      <SidebarFooter style={{ textAlign: 'center', background: '#222d32' }}>
        <div
          className="sidebar-btn-wrapper"
          style={{
            padding: '20px 24px',
          }}
        >
          <a
            href="http://www.verdaz.com.br"
            target="_blank"
            className="sidebar-btn"
            rel="noopener noreferrer"
          >
            <MdCopyright />
            <span> {'CopyRight 2021 ITEC Mais'}</span>
          </a>
        </div>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default Aside;
