import React, { useState } from "react";
import "./index.css";

import { TextField } from "@material-ui/core";

import DefaultButton from "../../components/DefaultButton";
import { useHistory } from "react-router-dom";
import { Get } from "../../utils/request";
import Footer from "../../Home/Footer";
import { useSelector } from "react-redux";
import { PAGE_KEYS, STORAGE_URL } from "../../variables";
import LogoHeader from "../../components/LogoHeader";
import InputText from "../../components/Inputs/InputText";

const LandingPage = props => {

    const generalStyle = useSelector(store => store.AppReducer.generalStyle);

    const [email, SetEmail] = useState('');
    const [showEmailError, SetEmailError] = useState(false);

    const history = useHistory();
    
    const pageStyle = useSelector(store => store.AppReducer.pagesStyles[PAGE_KEYS.landing]);
    const loadingStyle = useSelector(store => store.AppReducer.loading);

    const HandleGetStartedButton = async e => {
        e.preventDefault();
        if (email == "") {
            SetEmailError(true);
            return;
        }

        let response = await Get(`user/account-exists/${encodeURIComponent(email)}`);
        if (!response?.status) return;

        let pathname = response.exists === true ? "/login" : "/register";

        history.push({
            pathname: pathname,
            state: {
                email: email
            }
        });
    }

    if (loadingStyle) return <Skeleton/>;
    return (
        <div>
            <div className="header">
                <div className="flex fdrow w100 jcsb" style={{padding: "5px"}}>
                    <LogoHeader/>
                    <DefaultButton
                        text="Entrar"
                        to="/login"
                        style={{
                            ...(pageStyle && {backgroundColor: pageStyle["primary_color"]})
                        }}
                    />
                </div>
            </div>
            <div className="body">
                <div className="get-started-card">
                    <div
                        className="get-started-card-background"
                        style={{
                            ...(pageStyle && pageStyle["background_color"] && {backgroundColor: pageStyle["background_color"]}),
                            ...(pageStyle && pageStyle["background_image"] && {backgroundImage: `url(${STORAGE_URL + pageStyle["background_image"]})`})
                        }}
                    />
                    <div className="get-started-card-text">
                        <h1
                            style={{
                                ...(pageStyle?.font_color && {color: pageStyle?.font_color})
                            }}
                        >{pageStyle && pageStyle["title"]}</h1>
                        <h2 
                            className="text-center"
                            style={{
                                ...(pageStyle?.font_color && {color: pageStyle?.font_color})
                            }}
                        >{pageStyle && pageStyle["subtitle"]}</h2>
                        <h5 
                            className="text-center"
                            style={{
                                ...(pageStyle?.font_color && {color: pageStyle?.font_color})
                            }}
                        >{pageStyle && pageStyle["text"]}</h5>
                        <form
                            autoComplete="off"
                            className="get-started-card-form row"
                            // onSubmit={() => 
                            // })}
                        >
                            <InputText
                                label="E-mail"
                                variant="filled"
                                error={showEmailError}
                                helperText={showEmailError ? "Insira um e-mail!" : ""}
                                value={email}
                                onChange={(e) => SetEmail(e.target.value)}
                                className="col-sm-8"
                                type="email"
                                style={{
                                    width: "450px"
                                }}
                                InputProps={{
                                    style: {
                                        backgroundColor: "white",
                                        borderRadius: 0
                                    }
                                }}
                                focusedLabelColor={pageStyle && pageStyle["primary_color"]}
                                underlineColor={pageStyle && pageStyle["primary_color"]}
                            />
                            <DefaultButton
                                text="Bora lá"
                                bordersRadius={[false, false, false, false]}
                                className="col-sm-4"
                                style={{
                                    height: "56px",
                                    ...(pageStyle && {backgroundColor: pageStyle["primary_color"]})
                                }}
                                onClick={HandleGetStartedButton}
                                type="submit"
                            />
                        </form>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

const Skeleton = () => {
    return (
        <div>
            <div className="header flex w100 jcsb" style={{padding: "5px"}}>
                <div className="skeleton-base skeleton-shimmer" style={{width: "8em", height: "3.25em"}}></div>
                <div className="skeleton-base skeleton-shimmer" style={{width: "5em", height: "3.25em"}}></div>
            </div>
            <div className="get-started-card">
                <div className="get-started-card-text">
                    <div className="skeleton-small-text" style={{marginBottom: "28px"}}></div>
                    <div className="skeleton-medium-text" style={{marginBottom: "18px"}}></div>
                    <div className="skeleton-medium-text"></div>
                    <div className="skeleton-base skeleton-shimmer col-sm-8" style={{height: "3.25em"}}></div>
                </div>
            </div>
        </div>
    );
}

export default LandingPage;