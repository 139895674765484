import React, { useRef, useState } from "react";
import { FormLabel, FormControl as Input } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import { BsInfoCircle } from "react-icons/bs";
import DefaultButton from "../../../../components/DefaultButton";
import { Tooltip } from "@material-ui/core";

const FormCard = props => {

    const {
        onChange,
        initialCards
    } = props;

    const [title, SetTitle] = useState("");
    const [text, SetText] = useState("");
    const [cardList, SetCardList] = useState([...initialCards]);

    const firstInputRef = useRef(null);

    const HandleCardAdd = () => {
        if (!title && !text) {
            firstInputRef.current.focus();
            return;
        }

        let tempCardList = cardList;
        let cardData = {
            title: title,
            text: text
        };
        tempCardList.push(cardData);

        SetCardList([...tempCardList]);
        SetTitle("");
        SetText("");
        onChange(tempCardList);
        console.log(tempCardList)
        console.log(cardList)
    }

    const HandleCardRemove = index => {
        let tempCardList = cardList;

        tempCardList.splice(index, 1);
        SetCardList([...tempCardList]);
        onChange(tempCardList);
        console.log(tempCardList)
        console.log(cardList)
    }

    return (
        <div className="card-add-form">
            <div className="inline-flex">
                <FormLabel>
                    Título do card de divulgação 
                </FormLabel>
                <Tooltip
                    title="Os 'cards' de divulgação são os cartões com textos para promover os cursos na landing page"
                    arrow
                    placement="right"
                >
                    <div>
                        <BsInfoCircle color="#38a4f8"/>
                    </div>
                </Tooltip>
            </div>
            <Input
                placeholder="Título do card"
                value={title}
                onChange={e => SetTitle(e.target.value)}
                ref={firstInputRef}
            />
            <br/>
            <FormLabel>Texto do card de divulgação</FormLabel>
            <Input
                placeholder="Texto do card"
                value={text}
                onChange={e => SetText(e.target.value)}
                as="textarea"
            />
            <br/>
            
            <DefaultButton
                text="Adicionar card"
                style={{
                    width: "fit-content"
                }}
                onClick={HandleCardAdd}
            />
            
            <div className="table-responsive" hidden={cardList.length == 0}>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Título</th>
                            <th>Texto</th>
                            <th>Ação</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cardList.map((value, index) => (
                            <tr key={index}>
                                <td className="text-truncate" style={{maxWidth: "150px"}}>{value.title}</td>
                                <td className="text-truncate" style={{maxWidth: "150px"}}>{value.text}</td>
                                <td>
                                    <DefaultButton
                                        onClick={() => HandleCardRemove(index)}
                                        width="2.2em"
                                        height="2.2em"
                                        padding={0}
                                        title={`Remover card`}
                                        bg="danger"
                                        icon={<MdDelete size={17} color="white"/>}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default FormCard;