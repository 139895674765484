import React, { useEffect, useState } from "react";

import { Checkbox, TextField } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import DefaultButton from "../../components/DefaultButton";

import { Alert } from "@material-ui/lab";

import "./LoginBody.css";
import { PAGE_KEYS } from "../../variables";
import { useSelector } from "react-redux";
import InputText from "../../components/Inputs/InputText";

const LoginBody = props => {

    const {
        onSubmit,
        errors = [],
        link = null,
        loading = false,
        ...other
    } = props;

    const [email, SetEmail] = useState("");
    const [password, SetPassword] = useState("");
    const [remember, SetRemember] = useState(false);

    const [showEmailError, SetShowEmailError] = useState(false);
    const [showPasswordError, SetShowPasswordError] = useState(false);

    const location = useLocation();

    const pageStyle = useSelector(store => store.AppReducer.pagesStyles[PAGE_KEYS.login]);

    const HandleSubmitButton = e => {
        SetShowEmailError(!email);
        SetShowPasswordError(!password);
        if (!password || !email) {
            e.preventDefault();
            return;
        }

        let data = {
            email: email,
            password: password,
            remember: remember
        }

        onSubmit(data);
    }

    useEffect(() => {
        if (location.state) if (location.state.email) SetEmail(location.state.email);
    }, []);

    return (
        <div 
            className="login-body"
            style={{
                ...(pageStyle?.secondary_color && {backgroundColor: pageStyle?.secondary_color})
            }}
        >
            <div className="login-content">
                <h1 className="text-white">Entrar</h1>
                <form
                    className="login-main-form"
                >
                    <InputText
                        variant="filled"
                        label="E-mail"
                        value={email}
                        onChange={e => SetEmail(e.target.value)}
                        error={showEmailError}
                        helperText={showEmailError ? "Insira um e-mail!" : ""}
                        style={{
                            width: "100%",
                            marginBottom: "1em"
                        }}
                        InputProps={{
                            style: {
                                backgroundColor: "white"
                            }
                        }}
                        focusedLabelColor={pageStyle && pageStyle["primary_color"]}
                        underlineColor={pageStyle && pageStyle["primary_color"]}
                    />
                    <InputText
                        variant="filled"
                        label="Senha"
                        type="password"
                        value={password}
                        onChange={e => SetPassword(e.target.value)}
                        error={showPasswordError}
                        helperText={showPasswordError ? "Insira uma senha!" : ""}
                        style={{
                            width: "100%",
                            marginBottom: "2em"
                        }}
                        InputProps={{
                            style: {
                                backgroundColor: "white"
                            }
                        }}
                        focusedLabelColor={pageStyle && pageStyle["primary_color"]}
                        underlineColor={pageStyle && pageStyle["primary_color"]}
                    />
                    <DefaultButton
                        text="Entrar"
                        style={{
                            height: "44px",
                            width: "100%",
                            ...(pageStyle && {backgroundColor: pageStyle["primary_color"]})
                        }}
                        onClick={HandleSubmitButton}
                        bg="primary"
                        loading={loading}
                    />
                    <div className="w100 flex jcsb align-center">
                        <div className="flex fdrow align-center text-muted">
                            <Checkbox
                                className="text-muted"
                                value={remember}
                                onChange={e => SetRemember(e.target.checked)}
                            />
                            Lembre-se de mim
                        </div>
                        <a href="#" className="text-muted">Precisa de ajuda?</a>
                    </div>
                    <p className="text-muted">
                        Novo aqui? <Link 
                            className="text-primary" 
                            style={{
                                ...(pageStyle && {color: pageStyle["primary_color"]})
                            }}
                            to={{
                                pathname: "/register",
                                state: {
                                    email: email
                                }
                            }}
                        > 
                            Cadastre-se agora!
                        </Link>
                    </p>
                </form>
                <Alert severity="error" hidden={errors.length == 0}>
                    {errors[0]}
                    {link}
                </Alert>
            </div>
        </div>
    );
}

export default LoginBody;