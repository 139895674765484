import React, { useEffect } from 'react';
import Routes from './routes';
import {useSelector, useDispatch} from 'react-redux'

import "./index.css";
import { Get } from './utils/request';
import DynamicSnackbar from './DynamicSnackbar';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme, CssBaseline } from '@material-ui/core';

function App()  {
    const dispatch = useDispatch();
    const palette = useSelector(store => store.StylesReducer.palette);
    const themeMode = React.useMemo(() => createTheme(palette), [palette]);

    GetDesign();

    let token = localStorage.getItem('token');
    let user = localStorage.getItem('user');

    if (user === null || user === undefined || user == "undefined" || user == "null") {
        user = {};
        localStorage.clear();
    } else {
        user = JSON.parse(user);
    }
    dispatch({
        type: "login",
        payload: {
            token: token,
            user: user
        }
    });

    return (
        <>
            <ThemeProvider theme={themeMode}>
                <CssBaseline/>
                <Routes/>
                <DynamicSnackbar/>
            </ThemeProvider>
        </>
    );
}

const GetDesign = async () => {
    const dispatch = useDispatch();

    let generalStyle = await Get("styles/general");

    let pagesStyles = await Get("styles/pages");

    // console.log(pagesStyles);

    if (pagesStyles) dispatch({
        type: "pagesStyles",
        payload: {
            pagesStyles: pagesStyles
        }
    });
    
    if (generalStyle) dispatch({
        type: "generalStyle",
        payload: {
            generalStyle: generalStyle
        }
    });

    dispatch({
        type: "loading",
        payload: {
            loading: false
        }
    });

}

export default App;
