import React, { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { Get } from "../../../utils/request";
import Board from "./Board";
import { Pagination } from "@material-ui/lab";
import { useDispatch } from "react-redux";
import { Show } from "actions/SnackbarActions";
import ReloadButton from "components/Buttons/ReloadButton";

const Table = props => {

    const {
        search = ""
    } = props;

    const [emails, SetEmails] = useState([]);
    const [loading, SetLoading] = useState(false);

    const [page, SetPage] = useState(1);
    const [maxPage, SetMaxPage] = useState(1);

    const [loadError, SetLoadError] = useState(false);

    const dispatch = useDispatch();

    const GetData = async () => {
        SetLoading(true);
        let response = await Get(`emails?search=${search}&page=${page}`);
        SetLoading(false);
        if (response?.status === true) {
            SetEmails(response?.emails?.data);
            SetMaxPage(response?.emails?.last_page);
            SetLoadError(false);
        } else if (!response || !response?.status) {
            dispatch(Show({
                show: true,
                severity: "warning",
                message: "Falha ao carregar os e-mails"
            }));
            SetLoadError(true);
        }
    }

    useEffect(() => {
        GetData();
    }, [search, page]);

    return (
        <>
            <div className="table-responsive">
                <table className="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th>E-mail</th>
                            <th>Data registrado</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!loading && emails && emails.length != 0 && emails.map((value, index) => (
                            <Board
                                email={value["email"]}
                                createdAt={value["created_at"]}
                                key={index}
                                id={value["id"]}

                                OnDelete={GetData}
                            />
                        ))}
                    </tbody>
                </table>
                <div className="flex align-center jcc w100">
                    <p hidden={loading || (emails && emails.length != 0)}>Nenhum e-mail achado</p>
                </div>
            </div>
            <div className="flex align-center jcc w100">
                <CircularProgress className={loading ? "" : "hide"}/>
                <ReloadButton onClick={GetData} hidden={!loadError}/>
            </div>
            <Pagination
                className={(maxPage == 1 ? "hide" : "")}
                style={{display: "flex", justifyContent: "flex-end"}} 
                count={maxPage} 
                value={page}
                onChange={(_, value) => SetPage(value)}
            />
        </>
    );
}

export default Table;