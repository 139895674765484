import { API_URL, STORAGE_URL } from "../../../../../variables";

let jodit = undefined;

export const config = {
    language: 'pt_br',
    events: {
        afterInit: instance => { jodit = instance }
    },
    uploader: {
        url: `${API_URL}site-itec/news/upload-image`,
        filesVariableName: () => "image",
        insertImageAsBase64URI: false,
        withCredentials: false,
        imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
        headers: { "accept": `application/json`,'Authorization':'Bearer ' + localStorage.getItem('token') },
        method: "POST",
        pathVariableName: 'path',
        prepareData: data => data,
        isSuccess: resp => !resp.error,
        getMessage: e => e,
        process: response => {
            let files = [];
            files.unshift(STORAGE_URL + response.image);
            return {
                files: files
            };
        },
        defaultHandlerSuccess: (resp, elem) => {
            if (resp.files && resp.files.length) {
                resp.files.forEach((filename, index) => { //edetor insertimg function
                    console.log(filename)
                    jodit.selection.insertImage(filename, null, 250);
                });
            }
        }
    }
}