import React, { useEffect, useState } from "react";

import ColorPicker from 'material-ui-color-picker'
import { FormControlLabel, FormGroup } from "@material-ui/core";
import { FormControl as Input } from "react-bootstrap";

import ImageInput from "../../../../components/Inputs/ImageInput";
import ColorInput from "../../../../components/Inputs/ColorInput";
import { FormLabel } from "react-bootstrap";
import DefaultButton from "../../../../components/DefaultButton";
import Label from "../../../../components/InfoLabel";
import { Get } from "../../../../utils/request";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { PAGE_KEYS } from "../../../../variables";
import PreviewPage from "./PreviewPage";

const FormBody = props => {

    const {
        onConfirm,
        loading
    } = props;

    const [title, SetTitle] = useState("");
    const [subtitle, SetSubtitle] = useState("");
    const [text, SetText] = useState("");
    const [fontColor, SetFontColor] = useState("#000");
    const [backgroundImage, SetBackgroundImage] = useState("");
    const [backgroundColor, SetBackgroundColor] = useState("#000");
    const [primaryColor, SetPrimaryColor] = useState("#000");
    const [secondaryColor, SetSecondaryColor] = useState("#000");
    
    const [firstLoading, SetFirstLoading] = useState(false);

    const location = useLocation();
    const landingStyle = useSelector(store => store.AppReducer.pagesStyles);
    const dispatch = useDispatch();

    const GetData = async () => {
        let response = await Get(`styles/pages/key/${PAGE_KEYS.landing}`);
        if (response === undefined) {
            SetFirstLoading(true);
            return;
        }

        SetTitle(response?.title ?? "");
        SetSubtitle(response?.subtitle ?? "");
        SetText(response?.text ?? "");
        SetFontColor(response?.font_color ?? "");
        SetBackgroundImage(response?.background_image ?? "");
        SetBackgroundColor(response?.background_color ?? "");
        SetPrimaryColor(response?.primary_color ?? "");
        SetSecondaryColor(response?.secondary_color ?? "");
        SetFirstLoading(true);
    }

    useEffect(() => {
        GetData();
    }, []);

    useEffect(() => {
        console.log(backgroundImage instanceof File);
    })

    if (!firstLoading)return <Skeleton/>
    return (
        <div>
            <form>
                <FormGroup>
                    <Label
                        label="Título"
                        required
                        title="O título da landing page é mostrado logo acima do campo de e-mail"
                    />
                    <Input
                        placeholder="Título da página inicial"
                        variant="filled"
                        value={title}
                        onChange={e => SetTitle(e.target.value)}
                    />
                    <br/>

                    <Label
                        label="Subtítulo"
                        title="O subtítulo da landing page é mostrado abaixo do título"
                    />
                    <Input
                        placeholder="Subtítulo da página inicial"
                        variant="filled"
                        value={subtitle}
                        onChange={e => SetSubtitle(e.target.value)}
                    />
                    <br/>

                    <Label
                        label="Texto de apoio"
                        title="O texto de apoio é mostrado abaixo do subtítulo"
                    />
                    <Input
                        placeholder="Texto de apoio"
                        variant="filled"
                        value={text}
                        onChange={e => SetText(e.target.value)}
                        multiline
                    />
                    <br/>

                    <Label
                        label="Foto de fundo"
                        title="A foto de fundo é mostrada no plano de fundo da página de login"
                    />
                    <ImageInput
                        ImageChange={image => SetBackgroundImage(image)}
                        path={backgroundImage}
                    />
                    <br/>

                    <FormLabel>Cor da fonte</FormLabel>
                    <ColorInput
                        value={fontColor}
                        onChange={color => color !== undefined && SetFontColor(color)}
                    />
                    <br/>

                    <FormLabel>Cor de fundo <span style={{color: "red"}}>*</span></FormLabel>
                    <ColorInput
                        value={backgroundColor}
                        onChange={color => color !== undefined && SetBackgroundColor(color)}
                    />
                    <br/>
                    
                    <Label
                        label="Cor primária"
                        required
                        title="A cor primária é atribuida aos principais elementos da página, como títulos e botões"
                    />
                    <ColorInput
                        value={primaryColor}
                        onChange={color => color !== undefined && SetPrimaryColor(color)}
                    />
                    <br/>

                    <FormLabel>Cor secundária <span style={{color: "red"}}>*</span></FormLabel>

                    <ColorInput
                        value={secondaryColor}
                        onChange={color => {
                            color !== undefined && SetSecondaryColor(color)
                        }}
                    />
                </FormGroup>
            </form>
            <hr/>
            <Label
                label="Pré-visualização"
            />
            <PreviewPage
                title={title}
                subtitle={subtitle}
                text={text}
                fontColor={fontColor}
                backgroundImage={backgroundImage}
                backgroundColor={backgroundColor}
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
            />
            <br/>
            <div className="w100 inline-flex jcsb margin-top-1">
                <DefaultButton
                    type="submit"
                    bg="confirm"
                    text="Salvar configurações"
                    loadingtext="Salvando configurações"
                    loading={loading}
                    onClick={() => {
                        const data = {
                            title: title,
                            subtitle: subtitle,
                            text: text,
                            fontColor: fontColor,
                            backgroundImage: backgroundImage,
                            backgroundColor: backgroundColor,
                            primaryColor: primaryColor,
                            secondaryColor: secondaryColor
                        }

                        onConfirm(data);
                    }}
                />
            </div>
        </div>
    );
}

const Skeleton = () => {
    return (
        <div>
            <div className="skeleton-base skeleton-shimmer w100" style={{height: "2em"}}/>
            <br/>
            <div className="skeleton-base skeleton-shimmer w100" style={{height: "2em"}}/>
            <br/>
            <div className="skeleton-base skeleton-shimmer w100" style={{height: "2em"}}/>
            <br/>
            <div className="skeleton-base skeleton-shimmer" style={{width: "7em", height: "7em"}}/>
            <br/>
            <div className="skeleton-base skeleton-shimmer w100" style={{height: "2em"}}/>
            <br/>
            <div className="skeleton-base skeleton-shimmer w100" style={{height: "2em"}}/>
            <br/>
            <div className="skeleton-base skeleton-shimmer w100" style={{height: "2em"}}/>
            <br/>
            <div className="skeleton-base skeleton-shimmer w100" style={{height: "2em"}}/>
            <br/>
        </div>
    );
}

export default FormBody;