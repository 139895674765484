import RoleBased from "components/Role/RoleBased";
import React, { useEffect, useState } from "react";

import { FormLabel, FormControl as Input } from "react-bootstrap";
import { RiArrowGoBackLine } from "react-icons/ri";
import { useParams } from "react-router";
import DefaultButton from "components/DefaultButton";
import ImageInput from "components/Inputs/ImageInput";
import PhoneInput from "components/Inputs/PhoneInput";
import { Get } from "utils/request";

const FormBody = props => {

    const {
        OnConfirm,
        loading
    } = props;

    const [image, SetImage] = useState();
    const [name, SetName] = useState("");
    const [email, SetEmail] = useState("");
    const [password, SetPassword] = useState("");
    const [confirmPassword, SetConfirmPassword] = useState("");
    const [phone, SetPhone] = useState("");
    const [role, SetRole] = useState("");

    const [roles, SetRoles] = useState([]);

    const GetData = async () => {
        let response = await Get("roles");
        console.log(response?.roles);
        if (response?.status === true) {
            SetRoles(response?.roles);
        }
    }

    useEffect(GetData, []);
    return (
        <div className="profile-edit-form">
            <form>
                <FormLabel>Imagem de perfil <span style={{color: "red"}}>*</span></FormLabel>
                <ImageInput
                    ImageChange={value => SetImage(value)}
                    path={image}
                />
                <br/>

                <FormLabel>Nome <span style={{color: "red"}}>*</span></FormLabel>
                <Input
                    placeholder="Nome"
                    value={name}
                    onChange={e => SetName(e.target.value)}
                />
                <br/>

                <FormLabel>E-mail <span style={{color: "red"}}>*</span></FormLabel>
                <Input
                    placeholder="E-mail"
                    value={email}
                    onChange={e => SetEmail(e.target.value)}
                />
                <br/>

                <FormLabel>Senha <span style={{color: "red"}}>*</span></FormLabel>
                <Input
                    placeholder="Senha"
                    value={password}
                    type="password"
                    onChange={e => SetPassword(e.target.value)}
                />
                <br/>

                <FormLabel>Confirmar senha <span style={{color: "red"}}>*</span></FormLabel>
                <Input
                    placeholder="Confirmar senha"
                    value={confirmPassword}
                    type="password"
                    onChange={e => SetConfirmPassword(e.target.value)}
                />
                <br/>

                <FormLabel>Telefone</FormLabel>
                <PhoneInput
                    inputType="bootstrap"
                    GetValue={value => SetPhone(value)}
                    value={phone}
                />
                <br/>

                <RoleBased>
                    <FormLabel>Cargo</FormLabel>
                    <select className="form-select" value={role} onChange={e => SetRole(e.target.value)}>
                        {roles?.map((value, key) => (
                            <option
                                id={value?.id}
                                key={key}
                                value={value?.id}
                            >
                                {value?.name}
                            </option>
                        ))}
                    </select>
                </RoleBased>
            </form>
            <br/>
            <div className="w100 inline-flex jcsb mt-2">
                <DefaultButton
                    bg="secondary"
                    text="Voltar"
                    icon={<RiArrowGoBackLine />}
                    to={`/log/accounts`}
                />
                <DefaultButton
                    bg="confirm"
                    text="Criar conta"
                    onClick={() => {
                        let data = {
                            image: image,
                            name: name,
                            email: email,
                            password: password,
                            phone: phone,
                            role: role
                        };
                        OnConfirm(data);
                    }}
                    loading={loading}
                />
            </div>
        </div>
    );
}

export default FormBody;