import React, { useEffect, useState } from "react";

import LoginHeader from "./LoginHeader";
import LoginBody from "./LoginBody";

import { Post } from "../../utils/request";

import "./index.css";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../Home/Footer";
import { PAGE_KEYS, STORAGE_URL } from "../../variables";

const Login = () => {

    const [loading, SetLoading] = useState(false);

    const [errorsList, SetErrors] = useState([]);
    const [link, SetLink] = useState(null);

    const history = useHistory();

    const dispatch = useDispatch();

    const pageStyle = useSelector(store => store.AppReducer.pagesStyles[PAGE_KEYS.login])
    const loadingStyle = useSelector(store => store.AppReducer.loading);

    const Login = async (email, password) => {
        let form = new FormData();
        
        form.append("email", email);
        form.append("password", password);

        SetLoading(true);
        let response = await Post("auth/login", form);

        if (response["status"]) {
            let token = response["access_token"];
            let user = response["user"];

            localStorage.setItem("token", token);
            localStorage.setItem("user", JSON.stringify(user));

            dispatch({type: "login", payload: {token: token, user: user}});
        } else if (response["errors"]) {
            let errors = Object.values(response["errors"]);
            SetErrors(errors);
            
            if (response["link"]) SetLink(<Link to={response["link"]["href"]}>{response["link"]["message"]}</Link>)
            else SetLink(null);

            SetLoading(false);

            return;
        }

        history.push("/home");
    }

    if (loadingStyle) return <Skeleton/>;
    return (
        <div 
            className="login-wrapper"
            style={{
                ...(pageStyle && pageStyle["background_color"] && {backgroundColor: pageStyle["background_color"]}),
                ...(pageStyle && pageStyle["background_image"] && {backgroundImage: `url(${STORAGE_URL + pageStyle["background_image"]})`})
            }}
        >
            <LoginHeader/>
            <LoginBody
                onSubmit={data => {
                    if (data === null || data === undefined) return;
                    let email = data.email;
                    let password = data.password;
                    Login(email, password);
                }}
                errors={errorsList}
                link={link}
                loading={loading}
            />
            <Footer/>
        </div>
    );
}

const Skeleton = () => {
    return (
        <div>
            <div style={{height: "100px", padding: "5px"}}>
                <div className="skeleton-base skeleton-shimmer" style={{width: "8em", height: "3.25em"}}></div>
            </div>
            <div className="skeleton-base skeleton-shimmer" style={{
                width: "28em",
                height: "30em",
                margin: "auto"
            }}/>
        </div>
    );
}

export default Login;