import React, { useState } from "react";

import DefaultButton from "../../../../../components/DefaultButton";
import InputText from "../../../../../components/Inputs/InputText";
import PhoneInput from "../../../../../components/Inputs/PhoneInput";

const FormBody = props => {

    const {
        primaryColor
    } = props;

    return (
        <form className="w100 flex align-center fdcolumn">
            <InputText
                variant="filled"
                label="Nome"
                focusedLabelColor={primaryColor}
                underlineColor={primaryColor}
                style={{width: "90%", minWidth: "20em"}}
            />
            <InputText
                variant="filled"
                label="E-mail"
                focusedLabelColor={primaryColor}
                underlineColor={primaryColor}
                style={{width: "90%", minWidth: "20em"}}
            />
            <PhoneInput
                variant="filled"
                label="Telefone"
                focusedLabelColor={primaryColor}
                underlineColor={primaryColor}
                style={{width: "90%", minWidth: "20em"}}
            />
            <br/>
            <DefaultButton
                text="Fazer matrícula agora!"
                bg={primaryColor}
                style={{
                    fontSize: "18px"
                }}
            />
        </form>
    );
}

export default FormBody;