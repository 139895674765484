import React, { useEffect, useState } from "react";

import ColorPicker from 'material-ui-color-picker'
import { FormControlLabel, FormGroup, TextField } from "@material-ui/core";

import ImageInput from "../../../../components/Inputs/ImageInput";
import { FormLabel, FormControl as Input } from "react-bootstrap";
import DefaultButton from "../../../../components/DefaultButton";
import PhoneInput from "../../../../components/Inputs/PhoneInput";
import ColorInput from "../../../../components/Inputs/ColorInput";
import { Get } from "../../../../utils/request";
import Label from "../../../../components/InfoLabel";
import PreviewPage from "./PreviewPage";

const FormBody = props => {

    const {
        loading,
        onConfirm
    } = props;

    const [title, SetTitle] = useState("");
    const [icon, SetIcon] = useState("");
    const [smallLogo, SetSmallLogo] = useState("");
    const [logo, SetLogo] = useState("");
    const [whatsapp, SetWhatsapp] = useState("");
    const [landlinePhone, SetLandlinePhone] = useState("");
    const [email, SetEmail] = useState("");
    const [facebook, SetFacebook] = useState("");
    const [youtube, SetYoutube] = useState("");
    const [instagram, SetInstagram] = useState("");
    const [twitter, SetTwitter] = useState("");
    const [linkedin, SetLinkedin] = useState("");
    const [certificate, SetCertificate] = useState("");
    const [fontColor, SetFontColor] = useState("");
    const [primaryColor, SetPrimaryColor] = useState("#000");
    const [secondaryColor, SetSecondaryColor] = useState("#000");

    const [footerFontColor, SetFooterFontColor] = useState("");
    const [footerBackgroundColor, SetFooterBackgroundColor] = useState("");

    const [firstLoading, SetFirstLoading] = useState(false);

    const GetData = async () => {
        let response = await Get("styles/general");
        if (response === undefined) {
            SetFirstLoading(true);
            return;
        }
        console.log(response)
        SetTitle(response?.title ?? "");//tenho que verificar item por item para não ser nulo
        SetIcon(response?.icon ?? "");
        SetSmallLogo(response?.header_icon_small ?? "");
        SetLogo(response?.header_icon ?? "");
        SetWhatsapp(response.whatsapp_number ?? "");
        SetLandlinePhone(response.phone_number ?? "");
        SetEmail(response.email ?? "");
        SetFacebook(response.facebook_link ?? "");
        SetYoutube(response.youtube_link ?? "");
        SetInstagram(response.instagram_link ?? "");
        SetTwitter(response.twitter_link ?? "");
        SetLinkedin(response.linkedin_link ?? "");
        SetCertificate(response.certificate ?? "");
        SetPrimaryColor(response?.primary_color ?? "");
        SetSecondaryColor(response?.secondary_color ?? "");
        SetFontColor(response?.font_color ?? "");
        SetFooterFontColor(response?.footer_font_color ?? "");
        SetFooterBackgroundColor(response?.footer_background_color ?? "");
        SetFirstLoading(true);
    }

    useEffect(() => {
        GetData();
    }, []);

    if (!firstLoading) return <Skeleton/>;
    return (
        <div>
            <form>
                <FormGroup>
                    <Label
                        label="Título"
                        title="O título do site é o nome do site que aparecerá nas guias do navegador"
                        required
                    />
                    <Input
                        placeholder="Título do site"
                        variant="filled"
                        value={title}
                        onChange={e => SetTitle(e.target.value)}
                    />
                    <br/>

                    <Label
                        label="Ícone"
                        title="O ícone é a imagem que aparecerá nas guias do navegador. Tamanho recomendado: 32x32. Atenção! A imagem pode ser menor ou maior que os tamanhos recomendados."
                        required
                    />
                    <ImageInput
                        ImageChange={image => SetIcon(image)}
                        path={icon}
                    />

                    <Label
                        label="Logo pequeno"
                        title="O logo pequeno será mostrado quando o site for redimensionado em um tamanho muito pequeno. Tamanho recomendado: 52x52. Atenção! A imagem pode ser menor ou maior que os tamanhos recomendados."
                        required
                    />
                    <ImageInput
                        ImageChange={image => SetSmallLogo(image)}
                        path={smallLogo}
                    />

                    <Label
                        label="Logo maior"
                        title="Tamanho recomendado: 500x200. Atenção! A imagem pode ser menor ou maior que os tamanhos recomendados."
                        required
                    />
                    <ImageInput
                        ImageChange={image => SetLogo(image)}
                        path={logo}
                        boxStyle={{
                            width: "25em"
                        }}
                    />

                    <FormLabel>Whatsapp</FormLabel>
                    <PhoneInput
                        GetValue={value => SetWhatsapp(value)}
                        initialValue={whatsapp}
                        placeholder="Número do whatsapp"
                        inputType="bootstrap"
                    />
                    <br/>

                    <FormLabel>Telefone</FormLabel>
                    <PhoneInput
                        GetValue={value => SetLandlinePhone(value)}
                        initialValue={landlinePhone}
                        placeholder="Número do telefone fixo"
                        inputType="bootstrap"
                    />
                    <br/>

                    <FormLabel>E-mail</FormLabel>
                    <Input
                        placeholder="E-mail da empresa"
                        value={email}
                        onChange={e => SetEmail(e.target.value)}
                    />
                    <br/>

                    <FormLabel>Facebook</FormLabel>
                    <Input
                        placeholder="Link do perfil do Facebook"
                        value={facebook}
                        onChange={e => SetFacebook(e.target.value)}
                    />
                    <br/>

                    <FormLabel>Youtube</FormLabel>
                    <Input
                        placeholder="Link do canal do Youtube"
                        value={youtube}
                        onChange={e => SetYoutube(e.target.value)}
                    />
                    <br/>

                    <FormLabel>Instagram</FormLabel>
                    <Input
                        placeholder="Link do perfil do Instagram"
                        value={instagram}
                        onChange={e => SetInstagram(e.target.value)}
                    />
                    <br/>

                    <FormLabel>Twitter</FormLabel>
                    <Input
                        placeholder="Link do perfil do Twitter"
                        value={twitter}
                        onChange={e => SetTwitter(e.target.value)}
                    />
                    <br/>

                    <FormLabel>Linkedin</FormLabel>
                    <Input
                        placeholder="Link do perfil do Linkedin"
                        value={linkedin}
                        onChange={e => SetLinkedin(e.target.value)}
                    />
                    <br/>

                    <FormLabel>Certificado</FormLabel>
                    <Input
                        placeholder="Certificado"
                        value={certificate}
                        onChange={e => SetCertificate(e.target.value)}
                        as="textarea"
                        rows="3"
                    />
                    <br/>

                    <Label
                        label="Cor de fonte"
                        required
                    />
                    <ColorInput
                        value={fontColor}
                        onChange={color => color !== undefined && SetFontColor(color)}
                    />
                    <br/>

                    <Label
                        label="Cor primária"
                        required
                        title="A cor primária aparecerá nos elementos com mais destaque do site, como títulos e botões"
                    />
                    <ColorInput
                        value={primaryColor}
                        onChange={color => color !== undefined && SetPrimaryColor(color)}
                    />
                    <br/>

                    <Label
                        label="Cor secundária"
                        required
                    />
                    <ColorInput
                        value={secondaryColor}
                        onChange={color => {
                            color !== undefined && SetSecondaryColor(color)
                        }}
                    />
                </FormGroup>
            </form>
            <hr/>
            <Label
                label="Cor da fonte do rodapé"
            />
            <ColorInput
                value={footerFontColor}
                onChange={color => {
                    color !== undefined && SetFooterFontColor(color)
                }}
            />
            <Label
                label="Cor de fundo do rodapé"
            />
            <ColorInput
                value={footerBackgroundColor}
                onChange={color => {
                    color !== undefined && SetFooterBackgroundColor(color)
                }}
            />
            <hr/>
            <Label
                label="Pré-visualização"
            />
            <p 
                className="text-muted"
                style={{
                    fontSize: "12px"
                }}
            >Atenção! A página de design Geral tem como propósito modificar os elementos de todas as páginas. A página abaixo tem como intuito, apenas, demonstrar as modificações.</p>
            <PreviewPage
                title={"Título exemplo"}
                subtitle={"Subtítulo exemplo"}
                text={"Exemplo de texto"}
                fontColor={"#000000"}
                backgroundImage={""}
                backgroundColor={"#94a1af"}
                secondaryBackgroundColor={"#fff"}
                entryWays={"Vestibular | ENEM"}
                entryDate={"1ª Chamada: 12/12"}
                entryPrice={"R$..."}
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
            />
            <br/>
            <div className="w100 inline-flex jcsb margin-top-1">
                <DefaultButton
                    type="submit"
                    bg="confirm"
                    text="Salvar configurações"
                    loadingtext="Salvando configurações"
                    loading={loading}
                    onClick={() => {
                        const data = {
                            title: title,
                            icon: icon,
                            smLogo: smallLogo,
                            logo: logo,
                            whatsapp: whatsapp,
                            landlinePhone: landlinePhone,
                            email: email,
                            facebook: facebook,
                            youtube: youtube,
                            instagram: instagram,
                            twitter: twitter,
                            linkedin: linkedin,
                            certificate: certificate,
                            primaryColor: primaryColor,
                            secondaryColor: secondaryColor,
                            fontColor: fontColor,
                            footerFontColor: footerFontColor,
                            footerBackgroundColor: footerBackgroundColor
                        }

                        onConfirm(data);
                    }}
                />
            </div>
        </div>
    );
}

const Skeleton = () => {
    return (
        <div>
            <div className="skeleton-base skeleton-shimmer w100" style={{height: "2em"}}/>
            <br/>
            <div className="skeleton-base skeleton-shimmer" style={{width: "7em", height: "7em"}}/>
            <br/>
            <div className="skeleton-base skeleton-shimmer" style={{width: "7em", height: "7em"}}/>
            <br/>
            <div className="skeleton-base skeleton-shimmer" style={{width: "15em", height: "7em"}}/>
        </div>
    );
}

export default FormBody;