import React, { useState } from "react";
import FormBody from "./FormBody/index";

import { useDispatch } from "react-redux";

import { Show } from "../../../actions/SnackbarActions";
import { Post } from "../../../utils/request";
import SweetAlert from "react-bootstrap-sweetalert";

const GeneralConfig = () => {

    const [popup, SetPopup] = useState(false);
    const [popupMessage, SetPopupMessage] = useState("");

    const [loading, SetLoading] = useState(false);

    const dispatch = useDispatch();

    const Submit = async (data) => {
        console.log(data);

        if (!data.title) {
            SetPopup(true);
            SetPopupMessage("Insira um título!");
            return;
        } else if (!data.icon) {
            SetPopup(true);
            SetPopupMessage("Insira um ícone!");
            return;
        } else if (!data.smLogo) {
            SetPopup(true);
            SetPopupMessage("Insira um logo pequeno!");
            return;
        } else if (!data.logo) {
            SetPopup(true);
            SetPopupMessage("Insira um logo!");
            return;
        } else if (!data.fontColor) {
            SetPopup(true);
            SetPopupMessage("Insira uma cor de fonte!");
            return;
        } else if (!data.primaryColor) {
            SetPopup(true);
            SetPopupMessage("Insira uma cor primária!");
            return;
        } else if (!data.secondaryColor) {
            SetPopup(true);
            SetPopupMessage("Insira uma cor secundária!");
            return;
        }

        let form = new FormData();

        form.append("title", data.title);
        form.append("icon", data.icon);
        form.append("header_icon_small", data.smLogo);
        form.append("header_icon", data.logo);
        form.append("whatsapp_number", data?.whatsapp);
        form.append("phone_number", data?.landlinePhone);
        form.append("email", data?.email);
        form.append("facebook_link", data?.facebook);
        form.append("youtube_link", data?.youtube);
        form.append("instagram_link", data?.instagram);
        form.append("twitter_link", data.twitter);
        form.append("linkedin_link", data?.linkedin);
        form.append("certificate", data?.certificate);
        form.append("primary_color", data.primaryColor);
        form.append("secondary_color", data.secondaryColor);
        form.append("font_color", data.fontColor);
        form.append("footer_font_color", data.footerFontColor);
        form.append("footer_background_color", data.footerBackgroundColor);

        SetLoading(true);
        let response = await Post("styles/general/update", form);
        SetLoading(false);

        console.log(response);
        if (response["status"] === true) {
            dispatch(Show({
                show: true,
                message: response["message"],
                severity: "success"
            }));
        }
    }

    return (
        <div className="card">
            <div className="card-body">
                <SweetAlert
                    warning
                    show={popup}
                    title="Atenção!"
                    confirmBtnText="Ok"
                    onConfirm={() => SetPopup(false)}
                >
                    {popupMessage}
                </SweetAlert>
                <h2 className="title">Design do site</h2>
                <p className="text-muted">Aqui é possivel modificar o estilo geral do site</p>
                <hr/>
                <FormBody
                    onConfirm={data => Submit(data)}
                    loading={loading}
                />
            </div>
        </div>
    );
}

export default GeneralConfig;