
import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';

import { URL } from '../../../variables';
import { MdPrint, MdReply } from 'react-icons/md';
import { FaTrash } from 'react-icons/fa';
import { maskCep, maskCpf, maskDatas, maskPrice, maskTelefone } from '../../../Auxiliar/Masks';
import DefaultButton from '../../../components/DefaultButton';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';

import html2canvas from 'html2canvas';
import { Money } from '@material-ui/icons';
import SweetAlert from 'react-bootstrap-sweetalert';

const ViewRegistrations = () => {

    const token = useSelector(state => state.AppReducer.token);
    const dispatch = useDispatch();
    const { id } = useParams();
    const [redirect, setRedirect] = useState(false);
    const [loading_screen, setLoading_screen] = useState(true);
    const [loading_save, setLoading_save] = useState(false);
    const location = useLocation();

    const [path, setPath] = useState('/inscricoes' + location.search);
    const [msgErro, setMsgErro] = useState('');
    const [cep, setCep] = useState('');
    const [logradouro, setLogradouro] = useState('');
    const [bairro, setBairro] = useState('');
    const [complemento, setComplemento] = useState('');
    const [numero, setNumero] = useState('');
    const [estado, setEstado] = useState('');
    const [cidade, setCidade] = useState('');
    const [turnos, setTurnos] = useState('');
    const [polo_id, setPolo_id] = useState('');
    const [curso_id, setCurso_id] = useState('');
    const [turno, setTurno] = useState('');
    const [bolsa, setBolsa] = useState('');
    const [nome, setNome] = useState('');

    const [inscricao, setInscricao] = useState({});

    const [show_warning, setShow_warning] = useState(false);


    const [show_success, showSuccess] = useState(false);

    const Get_data = (id) => {
        console.log(location)
        setLoading_screen(true);
        fetch(`${URL}api/get_view_inscricao/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    dispatch(logout());
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    setRedirect(true);
                }
                else {
                    setInscricao(resp.inscricao);

                    setLoading_screen(false);
                    // setPolo_id(resp.inscricao.polo.instituicao+' - '+resp.inscricao.polo.cidade);
                    // setCurso_id(resp.inscricao.polo_curso.curso.nome);
                    // setTurno(resp.inscricao.turno);
                    // setBolsa(resp.inscricao.bolsa);
                    // setNome(resp.inscricao.nome);
                    // setSexo(resp.inscricao.sexo);
                    // setNascimento(resp.inscricao.nascimento);
                    // setCpf(resp.inscricao.cpf);
                    // setRg(resp.inscricao.rg);
                    // setEmail(resp.inscricao.email);
                    // setCelular(resp.inscricao.celular);
                    // setEstado_civil(resp.inscricao.estado_civil);
                    // setPai(resp.inscricao.pai);
                    // setMae(resp.inscricao.mae);
                    // setCep(resp.inscricao.cep);
                    // setLogradouro(resp.inscricao.logradouro);
                    // setComplemento(resp.inscricao.complemento);
                    // setNumero(resp.inscricao.numero);
                    // setBairro(resp.inscricao.bairro);
                    // setCidade(resp.inscricao.cidade);
                    // setEstado(resp.inscricao.estado);
                    // setObservacoes(resp.inscricao.observacoes);
                }

            } catch (err) {
                setRedirect(true);
                console.log(err);
            }
        })
            .catch((err) => {
                console.log(err);
                setLoading_screen(false);
                setRedirect(true);
            });
    }

    const Imprimir = useCallback((e) => {
        console.log('teste');
        setLoading_save(true);
        setTimeout(() => {
            // const doc = new jsPDF("p", "mm", "a4");

            // doc.html(document.getElementById('printable').innerHTML, {
            //     x: 0, y: 0, width,height, callback: function (pdf) {
            //         // pdf.save('DOC.pdf');
            //         doc.autoPrint();
            //         doc.output("dataurlnewwindow");
            //     }
            // });
            html2canvas(document.getElementById('div_informacoes'), { quality: 1, scale: 1.5, useCORS: true }).then(function (canvas) {
                var img = canvas.toDataURL('image/jpeg');
                let pdf = new jsPDF('portrait', 'mm', 'a4')
                const imgProps = pdf.getImageProperties(img);
                const pdfHeight = pdf.internal.pageSize.getHeight();
                const pdfWidth = (imgProps.width * pdfHeight) / imgProps.height;
                pdf.addImage(img, 'JPEG', 25, 10, pdfWidth - 20, pdfHeight - 15);
                pdf.addPage();
                html2canvas(document.getElementById('div_endereco'), { quality: 1, scale: 1.5, useCORS: true }).then(function (canvas) {
                    var img = canvas.toDataURL('image/jpeg');

                    const imgProps = pdf.getImageProperties(img);
                    // const pdfWidth = pdf.internal.pageSize.getWidth();

                    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

                    pdf.addImage(img, 'JPEG', 25, 10, pdfWidth - 20, pdfHeight - 5);
                    // pdf.autoPrint();
                    pdf.output("dataurlnewwindow");
                });
                // pdf.save(inscricao.id + '_' + inscricao.cpf + '.pdf');

            });
            // doc.text('texto', 15.8, 10)
            // doc.autoTable({
            //     theme: 'grid',
            //     tableLineWidth: 0.2,
            //     columnStyles: {
            //         titulo: { fontStyle: 'bold', textColor: 'black', cellWidth: 35, halign: 'right' },
            //         texto: { fontStyle: 'normal', halign: 'left' }
            //     },
            //     tableLineColor: 'black',
            //     headStyles: { fontSize: 10, textColor: 'black', fillColor: 'white', lineColor: 'black' },
            //     bodyStyles: { fontSize: 10, lineWidth: 0 },
            //     columns: [
            //         { header: 'Ficha de inscrição', dataKey: 'titulo' },
            //         { dataKey: 'texto' },
            //     ],
            //     body: [{ titulo: 'Polo:', texto: polo_id },
            //     { titulo: 'Curso:', texto: curso_id },
            //     { titulo: 'Pedido de bolsa:', texto: bolsa == true ? 'Sim' : 'Não' },
            //     { titulo: 'Turno:', texto: turno },
            //     { titulo: 'Nome:', texto: nome }]
            // });

            // doc.save('inscricao.pdf');
            setLoading_save(false);
        }, 2000);


        // doc.output('dataurlnewwindow');
        // window.open(doc.output('bloburl'))

        // doc.fromHTML(form, 10, 10);
        // doc.save('Pedido_inscrição_'+nome);
    }, [inscricao]);

    useEffect(() => {
        Get_data(id);
    }, [])

    const InscricaoPaid = useCallback(() => {
        setLoading_save(true);
        fetch(`${URL}api/registration/paid/${inscricao.id}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    dispatch(logout());
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);

                    setMsgErro(erro);
                    setLoading_save(false);
                }
                else {
                    setLoading_save(false);
                    setInscricao(resp.inscricao);
                    showSuccess(true);
                }
            } catch (err) {
                setLoading_save(false);
                setMsgErro('Erro ao marcar a inscrição como paga. Você está conectado a internet?');
                window.scrollTo(500, 0);

                console.log(err);
            }
        })
            .catch((err) => {
                setLoading_save(false);
                console.log(err);
                window.scrollTo(500, 0);

                setMsgErro('Erro ao marcar a inscrição como paga. Você está conectado a internet?');

            });
    }, [inscricao, token])

    return (
        <div className="row">
            {redirect == true && <Redirect to={path} />}
            <div className="col-12">
                <div className="card">
                    <div className="card-body" id="card">


                        <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Inscrição</h4>
                        <p style={{ fontSize: '1rem', color: 'black' }}>Pedido de inscrição para o vestibular</p>
                        <br />

                        {/* <hr /> */}
                        {loading_screen == false &&
                            <div id="printable">
                                {msgErro != '' && <div>
                                    <div className="alert alert-danger alert-dismissible mb-0" role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setMsgErro('')}></button>
                                        <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{msgErro}</p>
                                    </div>
                                    <br />
                                </div>}
                                <div className="row" >
                                    <div className="col-12" >
                                        <div>
                                            <p style={{
                                                color: 'black',
                                                fontSize: '15px',
                                                textAlign: 'center',
                                                marginBottom: 0,
                                                fontWeight: 'bold'
                                            }}>INFORMAÇÕES GERAIS</p>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Curso  </label>
                                            <div className="col-sm-12">
                                                <p style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{inscricao.curso.nome} - {inscricao.curso.nivel}</p>
                                            </div>
                                        </div>

                                        {inscricao.curso.nivel == 'Técnico' && <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Polo  </label>
                                            <div className="col-sm-12">
                                                <p style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{inscricao.polo.instituicao} - {inscricao.polo.cidade}</p>
                                            </div>
                                        </div>}

                                        {inscricao.curso.nivel == 'Graduação' && <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Modalidade  </label>
                                            <div className="col-sm-12">
                                                <p style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{inscricao.modalidade}</p>


                                            </div>
                                        </div>}



                                        <hr style={{ marginTop: 30 }} />
                                        <div>
                                            <p style={{
                                                color: 'black',
                                                fontSize: '15px',
                                                textAlign: 'center',
                                                marginBottom: 0,
                                                fontWeight: 'bold'
                                            }}>INFORMAÇÕES PESSOAIS</p>
                                        </div>
                                        {/* <br /> */}
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Nome  </label>
                                            <div className="col-sm-12">
                                                <p style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{inscricao.nome}</p>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Nome Social</label>
                                            <div className="col-sm-12">
                                                <p style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{inscricao.nome_social ?? '-'}</p>
                                            </div>
                                        </div>

                                        <div className="form-group  row">
                                            <div className=" col-sm-12">
                                                <div className="row">
                                                    <div className="col-md-12 ">
                                                        <label className="form-label col-form-label" style={{ color: 'black' }}>Gênero  </label>


                                                        <div className="input-group-prepend" style={{ marginBottom: "6px" }}>
                                                            <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
                                                                <div style={{ width: 120, border: '1px solid #ced4da', borderRadius: 5, padding: 5, marginRight: 10 }}>
                                                                    <label className="form-check-label " htmlFor="Manha" style={{ fontWeight: 'normal', marginRight: 10, color: 'black' }}>Masculino</label>

                                                                    <input className="form-check-input" type="radio" name="sexo" checked={inscricao.genero == 'Masculino'} readOnly />
                                                                </div>
                                                                <div style={{ width: 120, border: '1px solid #ced4da', borderRadius: 5, padding: 5, marginRight: 10 }}>
                                                                    <label className="form-check-label " htmlFor="Manha" style={{ fontWeight: 'normal', marginRight: 10, color: 'black' }}>Feminino</label>

                                                                    <input className="form-check-input" type="radio" name="sexo" checked={inscricao.genero == 'Feminino'} readOnly />
                                                                </div>
                                                                <div style={{ width: 140, border: '1px solid #ced4da', borderRadius: 5, padding: 5, marginRight: 10 }}>
                                                                    <label className="form-check-label " htmlFor="Manha" style={{ fontWeight: 'normal', marginRight: 10, color: 'black' }}>Personalizado</label>

                                                                    <input className="form-check-input" type="radio" name="sexo" checked={inscricao.genero == 'Personalizado'} readOnly />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        {inscricao.genero == 'Personalizado' && <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Personalizado  </label>
                                            <div className="col-sm-12">
                                                <p style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{inscricao.genero_personalizado}</p>


                                            </div>
                                        </div>}
                                        {inscricao.genero == 'Personalizado' && <div className="form-group row mt-2">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Pronome  </label>

                                            <div className="col-sm-12">
                                                <p style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{inscricao.pronome == null ? '-' : inscricao.pronome}</p>
                                            </div>
                                        </div>}

                                        <div className="form-group  row">
                                            <div className="col-md-12 ">
                                                <label className="form-label col-form-label" style={{ color: 'black' }}>Data de nascimento  </label>
                                                <p style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{moment(inscricao.nascimento).format('DD/MM/YYYY')}</p>
                                            </div>
                                        </div>

                                        <div className="form-group  row">
                                            <div className=" col-sm-12">
                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <label className=" col-form-label" style={{ color: 'black' }}>CPF  </label>
                                                        <p style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{maskCpf(inscricao.cpf)}</p>

                                                    </div>
                                                    <div className="col-md-4">
                                                        <label className=" col-form-label" style={{ color: 'black' }}>Identidade  </label>
                                                        <p style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{inscricao.rg}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>E-mail  </label>
                                            <div className="col-sm-12">
                                                <p style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{inscricao.email}</p>


                                            </div>
                                        </div>
                                        <div className="form-group  row">
                                            <div className=" col-sm-12">
                                                <div className="row">
                                                    <div className="col-md-4 ">
                                                        <label className=" col-form-label" style={{ color: 'black' }}>Celular  </label>
                                                        <p style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{maskTelefone(inscricao.celular)}</p>

                                                    </div>
                                                    <div className="col-md-8 ">
                                                        <label className=" col-form-label" style={{ color: 'black' }}>Estado Civil  </label>
                                                        <p style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{inscricao.estado_civil}</p>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Nome do Pai  </label>
                                            <div className="col-sm-12">
                                                <p style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{inscricao.pai}</p>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Nome da mãe  </label>
                                            <div className="col-sm-12">
                                                <p style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{inscricao.mae}</p>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Possui alguma deficiência?  </label>
                                            <div className="input-group-prepend" style={{ marginBottom: "6px" }}>
                                                <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
                                                    <div style={{ width: 80 }}>
                                                        <input className="form-check-input" type="radio" readOnly name="deficiencia" checked={inscricao.deficiencia == true} />
                                                        <label className="form-check-label " htmlFor="Manha" style={{ fontWeight: 'normal', marginLeft: 5 }}>Sim</label>
                                                    </div>
                                                    <div style={{ width: 80 }}>
                                                        <input className="form-check-input" type="radio" readOnly name="deficiencia" checked={inscricao.deficiencia == false} />
                                                        <label className="form-check-label " htmlFor="Manha" style={{ fontWeight: 'normal', marginLeft: 5 }}>Não</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <label hidden={!inscricao.deficiencia} className="col-sm-12 col-form-label" style={{ color: 'black' }}>Explique qual(is) deficiência(s) você possui  </label>
                                            <div className="col-sm-12 mt-2" hidden={!inscricao.deficiencia}>
                                                <p style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{inscricao.qual_deficiencia ?? '-'}</p>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Você gosta de ouvir música enquanto estuda ou faz avaliação? </label>
                                            <div className="input-group-prepend" style={{ marginBottom: "6px" }}>
                                                <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
                                                    <div style={{ width: 80 }}>
                                                        <input className="form-check-input" type="radio" name="likesMusic" readOnly checked={inscricao.ouve_musica == true} />
                                                        <label className="form-check-label " htmlFor="Manha" style={{ fontWeight: 'normal', marginLeft: 5 }}>Sim</label>
                                                    </div>
                                                    <div style={{ width: 80 }}>
                                                        <input className="form-check-input" type="radio" name="likesMusic" readOnly checked={inscricao.ouve_musica == false} />
                                                        <label className="form-check-label " htmlFor="Manha" style={{ fontWeight: 'normal', marginLeft: 5 }}>Não</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <label hidden={!inscricao.ouve_musica} className="col-sm-12 col-form-label" style={{ color: 'black' }}>Coloque aqui duas musicas (nome da música e cantor) que você gosta de ouvir enquanto estuda  </label>

                                            <div className="col-sm-12 mt-2" hidden={!inscricao.ouve_musica}>
                                                <p style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{inscricao.texto_musica ?? '-'}</p>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Status </label>
                                            <div className="col-sm-12 " >
                                                <p style={{ color: inscricao.paid==false?'darkgoldenrod':'green', fontSize: 16, marginBottom: 0 }}>{inscricao.paid == false ? 'Aguardando confirmação' : 'Paga'}</p>
                                            </div>
                                        </div>

                                        <hr style={{ marginTop: 30 }} />
                                    </div>
                                    <div className="col-12" >

                                        <div>
                                            <p style={{
                                                color: 'black',
                                                fontSize: '15px',
                                                textAlign: 'center',
                                                marginBottom: 0,
                                                marginTop: 20,
                                                fontWeight: 'bold'
                                            }}>ENDEREÇO</p>
                                        </div>
                                        {/* <div className="form-group row">
                                            <div className="col-sm-12">
                                                <a href={`https://www.google.com.br/maps/place/${inscricao.logradouro.replace(' ', '+')},+${inscricao.numero.replace(' ', '+')}+-+${inscricao.bairro.replace(' ', '+')},+${inscricao.cidade.replace(' ', '+')}+-+${inscricao.estado.replace(' ', '+')}`}
                                                    target="_blank"
                                                    style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{inscricao.logradouro},
                                                    {inscricao.numero} - {inscricao.bairro},
                                                    {inscricao.cidade}
                                                    - {inscricao.estado},
                                                    {inscricao.cep}
                                                </a>
                                            </div>
                                        </div> */}

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>CEP </label>
                                            <div className="col-sm-12">
                                                <p style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{maskCep(inscricao.cep)}</p>


                                            </div>
                                        </div>
                                        <div className="form-group  row">
                                            <div className=" col-sm-12">
                                                <div className="row">
                                                    <div className="col-md-8 col-12">

                                                        <label className="col-form-label" style={{ color: 'black' }}>Logradouro </label>
                                                        <p style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{inscricao.logradouro}</p>
                                                    </div>
                                                    <div className="col-md-4 col-12">
                                                        <label className="col-form-label" style={{ color: 'black' }}>Número </label>
                                                        <p style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{inscricao.numero ?? '-'}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group  row">
                                            <div className=" col-sm-12">
                                                <div className="row">
                                                    <div className="col-md-8 col-12">
                                                        <label className="col-form-label" style={{ color: 'black' }}>Bairro </label>
                                                        <p style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{inscricao.bairro}</p>
                                                    </div>
                                                    <div className="col-md-4 col-12">
                                                        <label className="col-form-label" style={{ color: 'black' }}>Complemento</label>
                                                        <p style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{inscricao.complemento == null ? '-' : inscricao.complemento}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group  row">
                                            <div className=" col-sm-12">
                                                <div className="row">
                                                    <div className="col-md-8 col-12">
                                                        <label className="col-form-label" style={{ color: 'black' }}>Cidade </label>
                                                        <p style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{inscricao.cidade}</p>
                                                    </div>
                                                    <div className="col-md-4 col-12">
                                                        <label className="col-form-label" style={{ color: 'black' }}>Estado</label>
                                                        <p style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{inscricao.estado}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-12" >

                                        {loading_save == false &&
                                            <div className="row mt-3">
                                                <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start" style={{ marginBottom: '0.5rem' }}>
                                                    <Link to={"/inscricoes" + location.search} style={{ textDecoration: 'none' }}>
                                                        <DefaultButton
                                                            type="button"
                                                            bg="secondary"
                                                            text="Voltar"
                                                            icon={<MdReply color="white" />}
                                                        />
                                                    </Link>
                                                </div>
                                                <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end" style={{ marginBottom: '0.5rem' }}>
                                                    {inscricao.paid == false && <DefaultButton
                                                        bg="primary"
                                                        type="button"
                                                        style={{ marginRight: 10 }}
                                                        text="Marcar como pago"
                                                        onClick={(e) => {
                                                            setShow_warning(true);
                                                        }}
                                                        icon={<Money color="white" style={{ marginRight: '5px' }}

                                                        />}
                                                    />}
                                                    <DefaultButton
                                                        bg="info"
                                                        type="button"
                                                        onClick={(e) => { console.log(e); Imprimir(e) }}

                                                        text="Imprimir"
                                                        icon={<MdPrint color="white" style={{ marginRight: '5px' }}
                                                        />}
                                                    />
                                                </div>

                                            </div>}

                                        {loading_save == true &&
                                            <div className="row">
                                                <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                                    <div className="spinner-border text-primary" role="status" ></div>
                                                </div>
                                            </div>}
                                    </div>






                                    <div className="col-12" id="div_informacoes" style={{ width: 760, height: 1180, position: 'absolute', top: '-2000px' }}>
                                        <div>
                                            <p style={{
                                                color: 'black',
                                                fontSize: '15px',
                                                textAlign: 'center',
                                                marginBottom: 0,
                                                fontWeight: 'bold'
                                            }}>INFORMAÇÕES GERAIS</p>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Curso  </label>
                                            <div className="col-sm-12">
                                                <p style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{inscricao.curso.nome} - {inscricao.curso.nivel}</p>
                                            </div>
                                        </div>

                                        {inscricao.curso.nivel == 'Técnico' && <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Polo  </label>
                                            <div className="col-sm-12">
                                                <p style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{inscricao.polo.instituicao} - {inscricao.polo.cidade}</p>
                                            </div>
                                        </div>}

                                        {inscricao.curso.nivel == 'Graduação' && <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Modalidade  </label>
                                            <div className="col-sm-12">
                                                <p style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{inscricao.modalidade}</p>


                                            </div>
                                        </div>}



                                        <hr style={{ marginTop: 30 }} />
                                        <div>
                                            <p style={{
                                                color: 'black',
                                                fontSize: '15px',
                                                textAlign: 'center',
                                                marginBottom: 0,
                                                fontWeight: 'bold'
                                            }}>INFORMAÇÕES PESSOAIS</p>
                                        </div>
                                        {/* <br /> */}
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Nome  </label>
                                            <div className="col-sm-12">
                                                <p style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{inscricao.nome}</p>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Nome Social</label>
                                            <div className="col-sm-12">
                                                <p style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{inscricao.nome_social ?? '-'}</p>
                                            </div>
                                        </div>

                                        <div className="form-group  row">
                                            <div className=" col-sm-12">
                                                <div className="row">
                                                    <div className="col-md-12 ">
                                                        <label className="form-label col-form-label" style={{ color: 'black' }}>Gênero  </label>


                                                        <div className="input-group-prepend" style={{ marginBottom: "6px" }}>
                                                            <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
                                                                <div style={{ width: 120, border: '1px solid #ced4da', borderRadius: 5, padding: 5, marginRight: 10 }}>
                                                                    <label className="form-check-label " htmlFor="Manha" style={{ fontWeight: 'normal', marginRight: 10, color: 'black' }}>Masculino</label>

                                                                    <input className="form-check-input" type="radio" name="sexo1" value={inscricao.genero} checked={inscricao.genero == 'Masculino'} disabled />
                                                                </div>
                                                                <div style={{ width: 120, border: '1px solid #ced4da', borderRadius: 5, padding: 5, marginRight: 10 }}>
                                                                    <label className="form-check-label " htmlFor="Manha" style={{ fontWeight: 'normal', marginRight: 10, color: 'black' }}>Feminino</label>

                                                                    <input className="form-check-input" type="radio" name="sexo1" value={inscricao.genero} checked={inscricao.genero == 'Feminino'} disabled />
                                                                </div>
                                                                <div style={{ width: 140, border: '1px solid #ced4da', borderRadius: 5, padding: 5, marginRight: 10 }}>
                                                                    <label className="form-check-label " htmlFor="Manha" style={{ fontWeight: 'normal', marginRight: 10, color: 'black' }}>Personalizado</label>

                                                                    <input className="form-check-input" type="radio" name="sexo1" value={inscricao.genero} checked={inscricao.genero == 'Personalizado'} disabled />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        {inscricao.genero == 'Personalizado' && <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Personalizado  </label>
                                            <div className="col-sm-12">
                                                <p style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{inscricao.genero_personalizado}</p>


                                            </div>
                                        </div>}
                                        {inscricao.genero == 'Personalizado' && <div className="form-group row mt-2">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Pronome  </label>

                                            <div className="col-sm-12">
                                                <p style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{inscricao.pronome == null ? '-' : inscricao.pronome}</p>
                                            </div>
                                        </div>}

                                        <div className="form-group  row">
                                            <div className="col-md-12 ">
                                                <label className="form-label col-form-label" style={{ color: 'black' }}>Data de nascimento  </label>
                                                <p style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{moment(inscricao.nascimento).format('DD/MM/YYYY')}</p>
                                            </div>
                                        </div>

                                        <div className="form-group  row">
                                            <div className=" col-sm-12">
                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <label className=" col-form-label" style={{ color: 'black' }}>CPF  </label>
                                                        <p style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{maskCpf(inscricao.cpf)}</p>

                                                    </div>
                                                    <div className="col-md-4">
                                                        <label className=" col-form-label" style={{ color: 'black' }}>Identidade  </label>
                                                        <p style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{inscricao.rg}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>E-mail  </label>
                                            <div className="col-sm-12">
                                                <p style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{inscricao.email}</p>


                                            </div>
                                        </div>
                                        <div className="form-group  row">
                                            <div className=" col-sm-12">
                                                <div className="row">
                                                    <div className="col-md-4 ">
                                                        <label className=" col-form-label" style={{ color: 'black' }}>Celular  </label>
                                                        <p style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{maskTelefone(inscricao.celular)}</p>

                                                    </div>
                                                    <div className="col-md-8 ">
                                                        <label className=" col-form-label" style={{ color: 'black' }}>Estado Civil  </label>
                                                        <p style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{inscricao.estado_civil}</p>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Nome do Pai  </label>
                                            <div className="col-sm-12">
                                                <p style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{inscricao.pai}</p>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Nome da mãe  </label>
                                            <div className="col-sm-12">
                                                <p style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{inscricao.mae}</p>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Possui alguma deficiência?  </label>
                                            <div className="input-group-prepend" style={{ marginBottom: "6px" }}>
                                                <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
                                                    <div style={{ width: 80 }}>
                                                        <input className="form-check-input" type="radio" readOnly name="deficiencia1" checked={inscricao.deficiencia == true} />
                                                        <label className="form-check-label " htmlFor="Manha" style={{ fontWeight: 'normal', marginLeft: 5 }}>Sim</label>
                                                    </div>
                                                    <div style={{ width: 80 }}>
                                                        <input className="form-check-input" type="radio" readOnly name="deficiencia1" checked={inscricao.deficiencia == false} />
                                                        <label className="form-check-label " htmlFor="Manha" style={{ fontWeight: 'normal', marginLeft: 5 }}>Não</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <label hidden={!inscricao.deficiencia} className="col-sm-12 col-form-label" style={{ color: 'black' }}>Explique qual(is) deficiência(s) você possui  </label>
                                            <div className="col-sm-12 mt-2" hidden={!inscricao.deficiencia}>
                                                <p style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{inscricao.qual_deficiencia ?? '-'}</p>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Você gosta de ouvir música enquanto estuda ou faz avaliação? </label>
                                            <div className="input-group-prepend" style={{ marginBottom: "6px" }}>
                                                <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
                                                    <div style={{ width: 80 }}>
                                                        <input className="form-check-input" type="radio" name="likesMusic1" readOnly checked={inscricao.ouve_musica == true} />
                                                        <label className="form-check-label " htmlFor="Manha" style={{ fontWeight: 'normal', marginLeft: 5 }}>Sim</label>
                                                    </div>
                                                    <div style={{ width: 80 }}>
                                                        <input className="form-check-input" type="radio" name="likesMusic1" readOnly checked={inscricao.ouve_musica == false} />
                                                        <label className="form-check-label " htmlFor="Manha" style={{ fontWeight: 'normal', marginLeft: 5 }}>Não</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <label hidden={!inscricao.ouve_musica} className="col-sm-12 col-form-label" style={{ color: 'black' }}>Coloque aqui duas musicas (nome da música e cantor) que você gosta de ouvir enquanto estuda  </label>

                                            <div className="col-sm-12 mt-2" hidden={!inscricao.ouve_musica}>
                                                <p style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{inscricao.texto_musica ?? '-'}</p>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Status </label>
                                            <div className="col-sm-12 " >
                                            <p style={{ color: inscricao.paid==false?'darkgoldenrod':'green', fontSize: 16, marginBottom: 0 }}>{inscricao.paid == false ? 'Aguardando confirmação' : 'Paga'}</p>
                                            </div>
                                        </div>

                                        <hr style={{ marginTop: 30 }} />
                                    </div>
                                    <div className="col-12" id="div_endereco" style={{ width: 760, height: 330, position: 'absolute', top: '-2000px' }} >

                                        <div>
                                            <p style={{
                                                color: 'black',
                                                fontSize: '15px',
                                                textAlign: 'center',
                                                marginBottom: 0,
                                                marginTop: 20,
                                                fontWeight: 'bold'
                                            }}>ENDEREÇO</p>
                                        </div>
                                        {/* <div className="form-group row">
                                            <div className="col-sm-12">
                                                <a href={`https://www.google.com.br/maps/place/${inscricao.logradouro.replace(' ', '+')},+${inscricao.numero.replace(' ', '+')}+-+${inscricao.bairro.replace(' ', '+')},+${inscricao.cidade.replace(' ', '+')}+-+${inscricao.estado.replace(' ', '+')}`}
                                                    target="_blank"
                                                    style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{inscricao.logradouro},
                                                    {inscricao.numero} - {inscricao.bairro},
                                                    {inscricao.cidade}
                                                    - {inscricao.estado},
                                                    {inscricao.cep}
                                                </a>
                                            </div>
                                        </div> */}

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>CEP </label>
                                            <div className="col-sm-12">
                                                <p style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{maskCep(inscricao.cep)}</p>


                                            </div>
                                        </div>
                                        <div className="form-group  row">
                                            <div className=" col-sm-12">
                                                <div className="row">
                                                    <div className="col-md-8 col-12">

                                                        <label className="col-form-label" style={{ color: 'black' }}>Logradouro </label>
                                                        <p style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{inscricao.logradouro}</p>
                                                    </div>
                                                    <div className="col-md-4 col-12">
                                                        <label className="col-form-label" style={{ color: 'black' }}>Número </label>
                                                        <p style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{inscricao.numero ?? '-'}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group  row">
                                            <div className=" col-sm-12">
                                                <div className="row">
                                                    <div className="col-md-8 col-12">
                                                        <label className="col-form-label" style={{ color: 'black' }}>Bairro </label>
                                                        <p style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{inscricao.bairro}</p>
                                                    </div>
                                                    <div className="col-md-4 col-12">
                                                        <label className="col-form-label" style={{ color: 'black' }}>Complemento</label>
                                                        <p style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{inscricao.complemento == null ? '-' : inscricao.complemento}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group  row">
                                            <div className=" col-sm-12">
                                                <div className="row">
                                                    <div className="col-md-8 col-12">
                                                        <label className="col-form-label" style={{ color: 'black' }}>Cidade </label>
                                                        <p style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{inscricao.cidade}</p>
                                                    </div>
                                                    <div className="col-md-4 col-12">
                                                        <label className="col-form-label" style={{ color: 'black' }}>Estado</label>
                                                        <p style={{ color: 'black', fontSize: 16, marginBottom: 0 }}>{inscricao.estado}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                </div>
                            </div>}

                        {/*-------------- loadings -------------*/}


                        {loading_screen == true &&
                            <div className="row">
                                <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                    <div className="spinner-border text-primary" role="status" ></div>
                                </div>
                            </div>}

                        <SweetAlert
                            warning
                            title={"Atenção"}
                            onConfirm={() => { setShow_warning(false); InscricaoPaid(); }}
                            onCancel={() => { setShow_warning(false); }}
                            show={show_warning}
                            confirmBtnText='Sim, desejo marcar'
                            cancelBtnText='Cancelar'
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            showCancel={true}
                        >
                            Deseja marcar a inscrição do candidato {inscricao.nome} como paga?

                        </SweetAlert>

                        <SweetAlert
                            success
                            title={"Sucesso"}
                            onConfirm={() => {
                                showSuccess(false);
                            }}
                            show={show_success}
                            confirmBtnText='OK'
                            confirmBtnBsStyle="primary"

                        >
                            Inscrição do candidato {inscricao.nome} foi marcada como paga

                        </SweetAlert>
                    </div>
                </div>
            </div >
        </div >
    );
}


export default ViewRegistrations;


