import { Tabs, Tab } from "@material-ui/core";
import TabPanel from "components/TabPanel";
import React, { useState } from "react";
import { useHistory } from "react-router";

const TabsContainer = props => {

    const {
        children,
        labels,
        ...other
    } = props;

    const [tab, SetTab] = useState(0);

    const history = useHistory();

    return (
        <div className="tabsContainer">
            <Tabs
                {...other}
                value={tab}
                onChange={(_, value) => {
                    SetTab(value);
                    history.push({search: `?t=${value}`});
                }}
            >
                {React.Children?.map(children, (_, key) => (
                    <Tab
                        label={labels[key]?.label}
                        className={labels[key]?.className}
                        key={key}
                    />
                ))}
            </Tabs>
            <div className="tabPanelGroup">
                {React.Children?.map(children, (child, key) => (
                    <TabPanel
                        value={tab}
                        index={key}
                        key={key}
                    >
                        {child}
                    </TabPanel>
                ))}
            </div>
        </div>
    );
}

export default TabsContainer;