import { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { MdDelete, MdModeEdit } from "react-icons/md";
import { useDispatch } from "react-redux";
import { Show } from "actions/SnackbarActions";
import DefaultButton from "components/DefaultButton";
import {Post} from "utils/request";
import {STORAGE_URL} from "variables";

const Board = props => {

  const {
    id,
    image,
    link,
    OnDelete,
    title
  } = props;

  const [popup, setPopup] = useState(false)
  const [loading, setLoading]= useState(false)
  const dispatch = useDispatch();

  const Delete = async () => {
    let form = new FormData();
    form.append('id', id);

    setLoading(true);
    let response = await Post("site-itec/bannerPrincipal-images/delete", form);
    setLoading(false);

    if(response?.status){
      dispatch(Show({
        show: true,
        message: response?.message,
        severity: response?.severity
      }));
      OnDelete();
    }
  }

  return(
    <tr>
      <td>
        <img src={image ?
            STORAGE_URL + image : ""}
            height="40"
        />
      </td>
      <td>
        {title}
      </td>
      <td className="table-text-dots">
          {link}
      </td>
      <td>
        <SweetAlert
          warning
          title={"Atenção"}
          onConfirm={()=>{
            Delete();
            setPopup(false);
          }}
          onCancel={()=>{
            setPopup(false);
          }}
          show={popup}
          confirmBtnText='Deletar'
          cancelBtnText='Cancelar'
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="danger"
          showCancel={true}
        >
          Tem certeza que deseja deletar esse banner?
        </SweetAlert>
        <DefaultButton 
          to={`/site-itec/bannerPrincipal-home/${id}/editar`}
          width="2.2em"
          height="2.2em"
          padding={0}
          title="Editar banner"
          bg="info"
          icon={<MdModeEdit
            color="white"
            size={17}/>  
          }
          style={{ margin: "0 2px" }}
        />
        <DefaultButton
          onClick={()=>{
            setPopup(true)
          }}
          width="2.2em"
          height="2.2em"
          padding={0}
          title="Deletar banner"
          bg="danger"
          loading={loading}
          icon={!loading &&
            <MdDelete 
              size={17}
              color="white"
            />
          } 
        />
      </td>
    </tr>
  )
}
export default Board;