import React, { useState } from "react";

// import { TextField } from "@material-ui/core";
import { FormControl as Input } from "react-bootstrap";

import Table from "./Table";
import DefaultButton from "../../../components/DefaultButton";

const EmailsLog = () => {

    const [search, SetSearch] = useState("");

    return (
        <div className="card">
            <div className="card-body">
                <h2 className="title">Lista de e-mails</h2>
                <p className="text-muted">Aqui são listados todos os e-mails já registrados no sistema</p>
                <hr/>
                <div className="flex jcsb flex-wrap margin-bottom">
                    <Input
                        variant="filled"
                        placeholder="Pesquisar registro por E-mail"
                        className="flex1"
                        value={search}
                        onChange={e => SetSearch(e.target.value)}
                    />
                </div>
                <Table
                    search={search}
                />
            </div>
        </div>
    );
}

export default EmailsLog;