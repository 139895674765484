import { Tooltip } from "@material-ui/core";
import React, { useEffect, useRef } from "react";
import DefaultButton from "../../../../../components/DefaultButton";
import InputText from "../../../../../components/Inputs/InputText";
import LogoHeader from "../../../../../components/LogoHeader";
import Label from "../../../../../components/InfoLabel";
import Footer from "../../../../../Home/Footer";
import { STORAGE_URL } from "../../../../../variables";

const PreviewPage = props => {

    const {
        title,
        subtitle,
        text,
        fontColor,
        backgroundImage,
        backgroundColor,
        primaryColor,
        secondaryColor
    } = props;

    const background = useRef(null);

    useEffect(() => {
        if (backgroundImage instanceof File && FileReader) {
            let fileReader = new FileReader();
            fileReader.onload = () => background.current.style.backgroundImage = `url(${fileReader.result})`;
            fileReader.readAsDataURL(backgroundImage);
        }
    }, [backgroundImage]);

    return (
        <div
            style={{
                aspectRatio: "16/9",
                backgroundColor: "black",
                overflowY: "scroll"
            }}
        >
            <div
                style={{
                    ...(backgroundColor && {backgroundColor: backgroundColor}),
                    ...(backgroundImage && {backgroundImage: `url(${STORAGE_URL + backgroundImage})`}),
                    backgroundSize: "cover"
                }}
                ref={background}
            >
                <div className="header">
                    <div className="flex fdrow w100 jcsb" style={{padding: "5px"}}>
                        {/* <Tooltip
                            title="Logo do cabeçalho. Pode ser alterado na página Geral"
                            arrow
                            placement="top"
                        > */}
                        <div className="flex jcc">
                            <LogoHeader/>
                            <Label iconColor={primaryColor} title="Logo do cabeçalho. Pode ser alterado na página Geral"/>
                        </div>
                        {/* </Tooltip> */}
                        <DefaultButton
                            text="Entrar"
                            title="Botão; Cor de referência: cor primária"
                            style={{
                                ...(primaryColor && {backgroundColor: primaryColor})
                            }}
                        />
                    </div>
                </div>
                <div className="body">
                    <div className="get-started-card">
                        <div className="get-started-card-background"/>
                        <div className="get-started-card-text">
                            {/* <Tooltip
                                title="Título da página; Cor de referência: cor da fonte"
                                arrow
                                placement="right"
                            > */}
                            <div className="flex jcc">
                                <h1
                                    style={{
                                        ...(fontColor && {color: fontColor})
                                    }}
                                >{title}</h1>
                                <Label iconColor={primaryColor} title="Título da página; Cor de referência: cor da fonte"/>
                            </div>
                            {/* </Tooltip> */}
                            {/* <Tooltip
                                title="Título da página; Cor de referência: cor da fonte"
                                arrow
                                placement="right"
                            > */}
                            <div className="flex jcc">
                                <h2 
                                    className="text-center"
                                    style={{
                                        ...(fontColor && {color: fontColor})
                                    }}
                                >{subtitle}</h2>
                                <Label iconColor={primaryColor} title="Título da página; Cor de referência: cor da fonte"/>
                            </div>
                            {/* </Tooltip> */}
                            {/* <Tooltip
                                title="Título da página; Cor de referência: cor da fonte"
                                arrow
                                placement="right"
                            > */}
                            <div className="flex jcc">
                                <h5 
                                    className="text-center"
                                    style={{
                                        ...(fontColor && {color: fontColor})
                                    }}
                                >{text}</h5>
                                <Label iconColor={primaryColor} title="Título da página; Cor de referência: cor da fonte"/>
                            </div>
                            {/* </Tooltip> */}
                            <form
                                autoComplete="off"
                                className="get-started-card-form row"
                            >
                                <Tooltip
                                    title="Caixa de texto; Cor de referência: cor primária"
                                    arrow
                                    placement="bottom"
                                >
                                <InputText
                                    label="E-mail"
                                    variant="filled"
                                    value=""
                                    className="col-sm-8"
                                    type="email"
                                    style={{
                                        width: "450px"
                                    }}
                                    InputProps={{
                                        style: {
                                            backgroundColor: "white",
                                            borderRadius: 0
                                        }
                                    }}
                                    focusedLabelColor={primaryColor}
                                    underlineColor={primaryColor}
                                />
                                </Tooltip>
                                <DefaultButton
                                    text="Bora lá"
                                    bordersRadius={[false, false, false, false]}
                                    className="col-sm-4"
                                    style={{
                                        height: "56px",
                                        ...(primaryColor && {backgroundColor: primaryColor})
                                    }}
                                    title="Botão; Cor de referência: cor primária"
                                />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default PreviewPage;