import React, { useEffect, useState } from "react";

import { Card as MaterialCard, CardMedia, Popover } from "@material-ui/core";
import placeholder from "../../../../../assets/images/logo.png";
import { STORAGE_URL } from "../../../../../variables";
import CardDescription from "./CardDescription";

const CardModal = props => {

    const {
        open,
        anchor,
        id,
        image,
        name,
        description,
        listed,
        GetData,
        HandlePopoverClose,
        OnExpand = () => {},
        ...other
    } = props;

    const [hover, SetHover] = useState(true);

    return (
        // <Popover
        //     id={open ? "simple-popover" : undefined}
        //     anchorEl={anchor}
        //     open={open}
        //     anchorOrigin={{
        //         vertical: 'center',
        //         horizontal: 'center',
        //     }}
        //     transformOrigin={{
        //         vertical: "center",
        //         horizontal: "center"
        //     }}
        //     onMouseEnter={() => SetHover(true)}
        //     TransitionProps={{ timeout: 0, delay: 0 }}
        //     disableRestoreFocus
        //     disableScrollLock
        // >
            // <div 
            //     onMouseLeave={() => {
            //         HandlePopoverClose();
            //         SetHover(false);
            //     }}
            //     style={{
            //         height: "100%",
            //         width: "100%"
            //     }}
            // >
                <MaterialCard
                    {...other}
                >
                    {/* <CardMedia
                        component="img"
                        height="200"
                        image={image ? STORAGE_URL + image : placeholder}
                        alt="Curso card"
                    /> */}
                    <CardDescription
                        id={id}
                        name={name}
                        description={description}
                        listed={listed}
                        GetData={GetData}
                        OnExpand={OnExpand}
                    />
                </MaterialCard>
            // </div>
        // </Popover>
    );
}

export default CardModal;