import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";

import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import "./index.css";

import SwiperCore, {
    Pagination, Navigation
} from 'swiper';

SwiperCore.use([Pagination,Navigation]);

const CarouselSlider = props => {

    const {
        title = "",
        items = [],
        children,
        swiperProps,
        swiperSlideProps
    } = props;

    return (
        <div className="carousel">
            <div className="carousel-title">
                <h4>{title}</h4>
            </div>
            <Swiper 
                slidesPerView={1} 
                loopFillGroupWithBlank={true}
                pagination={{
                    clickable: "true"
                }}
                navigation={true}
                className="mySwiper"
                breakpoints={{
                    "500": {
                        slidesPerView: 2
                    },
                    "600": {
                        slidesPerView: 3
                    }
                }}
                {...swiperProps}
            >
                {children.map((value, index) => (
                    <SwiperSlide 
                        key={index}
                        style={{
                            width: "fit-content !important"
                        }}
                        {...swiperSlideProps}
                    >
                        {value}
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}

export default CarouselSlider;