import React from "react";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import DefaultButton from "../../../components/DefaultButton";
import CarouselGroup from "./CarouselGroup";

const CoursesUserView = () => {
    return (
        <div className="user-view">
            <CarouselGroup/>
        </div>
    );
}

export default CoursesUserView;