import DrawableInput from "components/Inputs/DrawableInput";
import RoleBased from "components/Role/RoleBased";
import React, { useEffect, useRef, useState } from "react";

import { FormLabel, FormControl as Input } from "react-bootstrap";
import { RiArrowGoBackLine } from "react-icons/ri";
import { useLocation, useParams } from "react-router";
import { STORAGE_URL } from "variables";
import DefaultButton from "../../../components/DefaultButton";
import ImageInput from "../../../components/Inputs/ImageInput";
import PhoneInput from "../../../components/Inputs/PhoneInput";
import { Get } from "../../../utils/request";

const FormBody = props => {

    const {
        OnConfirm,
        loading
    } = props;

    const [image, SetImage] = useState();
    const [name, SetName] = useState("");
    const [email, SetEmail] = useState("");
    const [password, SetPassword] = useState("");
    const [confirmPassword, SetConfirmPassword] = useState("");
    const [phone, SetPhone] = useState("");
    const [role, SetRole] = useState("");
    const [signature, SetSignature] = useState("");

    const [roles, SetRoles] = useState([]);

    const [firstLoading, SetFirstLoading] = useState(false);

    const { user_id } = useParams();
    const location = useLocation();

    const signatureContainer = useRef();

    const GetData = async () => {

        //ROLES
        let response = await Get("roles");
        if (response?.status === true) {
            SetRoles(response?.roles);
        }

        //USERS
        response = await Get(`user?id=${user_id}`);
        if (response?.status === true) {
            SetImage(response?.user?.profile_image ?? "");
            SetName(response?.user?.name);
            SetEmail(response?.user?.email);
            SetPhone(response?.user?.phone ?? "");
            SetRole(response?.user?.role_id);
            SetSignature(response?.user?.signature);
        }
        SetFirstLoading(true);
    }

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        if (signatureContainer?.current && query.get("sig") == 1) signatureContainer?.current.scrollIntoView();
    }, [signatureContainer, firstLoading]);

    useEffect(GetData, []);

    if (!firstLoading) return <Skeleton/>;
    return (
        <div className="profile-edit-form">
            <form>
                <FormLabel>Imagem de perfil <span style={{color: "red"}}>*</span></FormLabel>
                <ImageInput
                    ImageChange={value => SetImage(value)}
                    path={image}
                />
                <br/>

                <FormLabel>Nome <span style={{color: "red"}}>*</span></FormLabel>
                <Input
                    placeholder="Nome"
                    value={name}
                    onChange={e => SetName(e.target.value)}
                />
                <br/>

                <FormLabel>E-mail <span style={{color: "red"}}>*</span></FormLabel>
                <Input
                    placeholder="E-mail"
                    value={email}
                    onChange={e => SetEmail(e.target.value)}
                    disabled
                />
                <br/>

                <FormLabel>Senha <span style={{color: "red"}}>*</span></FormLabel>
                <Input
                    placeholder="Senha"
                    value={password}
                    type="password"
                    onChange={e => SetPassword(e.target.value)}
                />
                <br/>

                <FormLabel>Confirmar senha <span style={{color: "red"}}>*</span></FormLabel>
                <Input
                    placeholder="Confirmar senha"
                    value={confirmPassword}
                    type="password"
                    onChange={e => SetConfirmPassword(e.target.value)}
                />
                <br/>

                <FormLabel>Telefone</FormLabel>
                <PhoneInput
                    inputType="bootstrap"
                    GetValue={value => SetPhone(value)}
                    value={phone}
                />
                <br/>

                <RoleBased>
                    <FormLabel>Cargo</FormLabel>
                    <select className="form-select" value={role} onChange={e => SetRole(e.target.value)}>
                        {roles?.map((value, key) => (
                            <option
                                id={value?.id}
                                key={key}
                                value={value?.id}
                            >
                                {value?.name}
                            </option>
                        ))}
                    </select>
                    <br/>
                </RoleBased>

                <FormLabel>Assinatura</FormLabel>
                <DrawableInput
                    onChange={value => SetSignature(value)}
                    prevImage={signature}
                    ref={signatureContainer}
                    // initialMode={signature ? 1 : 0}
                />
            </form>
            <br/>
            <div className="w100 inline-flex jcsb mt-2">
                <DefaultButton
                    bg="secondary"
                    text="Voltar"
                    icon={<RiArrowGoBackLine />}
                    to={`/profile/view/${user_id}`}
                />
                <DefaultButton
                    bg="confirm"
                    text="Salvar alterações"
                    onClick={() => {
                        let data = {
                            image: image,
                            name: name,
                            email: email,
                            password: password,
                            phone: phone,
                            role: role,
                            signature: signature
                        };
                        OnConfirm(data);
                    }}
                    loading={loading}
                />
            </div>
        </div>
    );
}

const Skeleton = () => {
    return (
        <div>
            <div className="skeleton-base skeleton-shimmer" style={{width: "7em", height: "7em"}}/>
            <br/>
            <div className="skeleton-base skeleton-shimmer w100" style={{height: "2em"}}/>
            <br/>
            <div className="skeleton-base skeleton-shimmer w100" style={{height: "2em"}}/>
            <br/>
            <div className="skeleton-base skeleton-shimmer w100" style={{height: "2em"}}/>
            <br/>
            <div className="skeleton-base skeleton-shimmer w100" style={{height: "2em"}}/>
            <br/>
        </div>
    );
}

export default FormBody;