import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LogoHeader from "../../components/LogoHeader";
import { STORAGE_URL } from "../../variables";

import "./LoginHeader.css";

const LoginHeader = () => {

    const generalStyle = useSelector(store => store.AppReducer.generalStyle);

    return (
        <div className="login-header">
            <LogoHeader/>
        </div>
    );
}

export default LoginHeader;