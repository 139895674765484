import React, { useState } from "react";
import { FormLabel, FormControl as Input } from "react-bootstrap";
import { RiArrowGoBackLine } from "react-icons/ri";
import DefaultButton from "components/DefaultButton";
import ImageInput from "components/Inputs/ImageInput";

const FormBody = props => {

    const {
        loading,
        OnConfirm
    } = props;

    const [image, SetImage] = useState();
    const [link, SetLink] = useState("");
    const [title, setTitle] = useState("");

    return(
        <div className="form-course">
            <form>
                <FormLabel>Imagem do banner <span style={{color: "red"}}>*</span></FormLabel>
                <ImageInput
                    ImageChange={image => SetImage(image)}
                />
                <br/>
                <FormLabel>Título <span style={{color: "red"}}>*</span></FormLabel>
                <Input
                placeholder="Título"
                value={title}
                onChange={e => setTitle(e.target.value)}
                required
                />
                <br/>
                <FormLabel>Link para redirecionar</FormLabel>
                <Input
                    placeholder="Link"
                    value={link}
                    onChange={e => SetLink(e.target.value)}
                />
            </form>
            <br/>
            <div className="w100 inline-flex jcsb mt-2">
                <DefaultButton
                    bg="secondary"
                    text="Voltar"
                    icon={<RiArrowGoBackLine />}
                    to="/site-itec/banner-home"
                />
                <DefaultButton
                    bg="confirm"
                    text="Adicionar banner"
                    onClick={() => {
                        let data = {
                            image: image,
                            link: link,
                            title: title
                        };
                        OnConfirm(data);
                    }}
                    loading={loading}
                />
            </div>
        </div>
    );
}

export default FormBody;