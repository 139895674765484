import React from "react";
import { useSelector } from "react-redux";
import ReactPlayer from "react-player";
import DefaultButton from "../../../../../components/DefaultButton";
import LogoHeader from "../../../../../components/LogoHeader";
import SocialMedias from "../../../../../components/SocialMedias";
import Label from "../../../../../components/InfoLabel";
import Footer from "../../../../../Home/Footer";
import FormBody from "./FormBody";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { Tooltip } from "@material-ui/core";

const PreviewPage = props => {

    const {
        title,
        subtitle,
        text,
        catchPhrase,
        description,
        cards,
        entryWays,
        entryDate,
        entryPrice,
        fontColor,
        backgroundColor,
        secondaryBackgroundColor,
        primaryColor,
        secondaryColor,
        videoLink,
        courseName
    } = props;

    const generalStyle = useSelector(store => store.AppReducer.generalStyle);

    return (
        <div 
            className="w100" 
            style={{
                aspectRatio: "16/9",
                backgroundColor: "black",
                overflowY: "scroll"
            }}
        >
            <div 
                className="course-landing-page"
                style={{
                    height: "fit-content",
                    ...(backgroundColor && {backgroundColor: backgroundColor})
                }}
            >
                <div 
                    className="course-landing-page-header"
                    style={{
                        ...(secondaryColor && {backgroundColor: secondaryColor})
                    }}
                >
                    <div className="flex align-center fd-row">
                        {/* <Tooltip 
                            title="Logo do cabeçalho. Pode ser alterado na página Geral"
                            arrow
                            placement="top"
                        > */}
                            <LogoHeader style={{marginRight: ".5em"}}/>
                            <Label iconColor={primaryColor} title="Logo do cabeçalho. Pode ser alterado na página Geral"/>
                        {/* </Tooltip> */}
                        <div className="flex align-center fd-row" hidden={!generalStyle.whatsapp_number} style={{marginLeft: "10px"}}>
                            <AiOutlineWhatsApp color="white" size={23} style={{marginRight: ".5em"}}/>
                            <span className="text-muted">{generalStyle.whatsapp_number}</span>
                        </div>
                    </div>
                    <SocialMedias/>
                </div>
                <div className="course-landing-page-body">
                    <div id="block1" style={{marginBottom: "40px"}}>
                        <div className="course-landing-page-title">
                            {/* <Tooltip 
                                title="Título da página. Cor de referência: cor primária"
                                arrow
                                placement="top"
                            > */}
                            <div className="inline-flex">
                                <h1 
                                    id="title teste"
                                    style={{
                                        ...(primaryColor && {color: primaryColor}),
                                        userSelect: "none"
                                    }}
                                >{title}</h1>
                                <Label iconColor={primaryColor} title="Título da página. Cor de referência: cor primária"/>
                            </div>
                            {/* </Tooltip> */}
                            {/* <Tooltip 
                                title="Subtítulo da página. Cor de referência: cor da fonte"
                                arrow
                                placement="bottom"
                            > */}
                            <div className="inline-flex">
                                <h2 
                                    id="subtitle"
                                    style={{
                                        ...(fontColor && {color: fontColor}),
                                        userSelect: "none"
                                    }}
                                >{subtitle}</h2>
                                <Label iconColor={primaryColor} title="Subtítulo da página. Cor de referência: cor da fonte"/>
                            </div>
                            {/* </Tooltip> */}
                        </div>
                        <div className="course-landing-page-content">
                            <div id="left" className="col-lg flex1" style={{padding: "15px"}}>
                                {/* <Tooltip 
                                    title="Texto de apoio. Cor de referência: cor da fonte"
                                    arrow
                                    placement="top"
                                > */}
                                <div className="inline-flex">
                                    <h4
                                        style={{
                                            ...(fontColor && {color: fontColor}),
                                            textAlign: "center",
                                            wordBreak: "break-word"
                                        }}
                                    >{text}</h4>
                                    <Label iconColor={primaryColor} title="Texto de apoio. Cor de referência: cor da fonte"/>
                                </div>
                                {/* </Tooltip> */}
                                {/* <Tooltip 
                                    title="Formulário e botão. Cor de referência: cor primária"
                                    arrow
                                    placement="bottom"
                                > */}
                                    <div className="w100 inline-flex">
                                        <FormBody
                                            primaryColor={primaryColor}
                                        />
                                        <Label iconColor={primaryColor} title="Formulário e botão. Cor de referência: cor primária"/>
                                    </div>
                                {/* </Tooltip> */}
                            </div>
                            <div id="right" className="col-lg flex1" hidden={!videoLink}>
                                <ReactPlayer
                                    url={videoLink ? videoLink : ""}
                                    controls={true}
                                    width="50%"
                                />
                            </div>
                        </div>
                    </div>
                    
                    <div 
                        id="block2" 
                        className="w100 flex jcc" 
                        style={{
                            padding: "40px",
                            ...(secondaryBackgroundColor && {backgroundColor: secondaryBackgroundColor})
                        }}
                        hidden={!catchPhrase}
                    >
                        <div className="inline-flex">
                            <h1
                                className="title text-center"
                                style={{
                                    ...(secondaryColor && {color: secondaryColor})
                                }}
                            >{catchPhrase}</h1>
                            <Label iconColor={primaryColor} title="Frase de efeito. Cor de referência: cor secundária"/>
                        </div>
                    </div>
                    <div 
                        id="block3" 
                        className="flex fdrow flex-wrap"
                        style={{
                            ...(secondaryBackgroundColor && {backgroundColor: secondaryBackgroundColor})
                        }}
                    >
                        {cards && cards?.map((value, index) => (
                            <div 
                                className="col-lg text-center w100"
                                style={{
                                    padding: "25px"
                                }}
                                key={index}
                                hidden={!cards}
                            >
                                {/* <Tooltip 
                                    title="Título card de divulgação. Cor de referência: cor secundária"
                                    arrow
                                    placement="top"
                                > */}
                                <div className="inline-flex">
                                    <h3
                                        className="title"
                                        style={{
                                            ...(secondaryColor && {color: secondaryColor}),
                                            userSelect: "none"
                                        }}
                                    >
                                        {value?.title}
                                    </h3>
                                    <Label iconColor={primaryColor} title="Título card de divulgação. Cor de referência: cor secundária"/>
                                </div>
                                {/* </Tooltip> */}
                                {/* <Tooltip 
                                    title="Texto card de divulgação. Cor de referência: cor da fonte"
                                    arrow
                                    placement="bottom"
                                > */}
                                <div>
                                    <p
                                        style={{
                                            ...(fontColor && {color: fontColor}),
                                            userSelect: "none"
                                        }}
                                    >
                                        {value?.text}
                                    </p>
                                    <Label iconColor={primaryColor} title="Texto card de divulgação. Cor de referência: cor da fonte"/>
                                </div>
                                {/* </Tooltip> */}
                            </div>
                        ))}
                    </div>
                    <div id="block4" className="text-center" style={{padding: "40px"}} hidden={!description}>
                        {/* <Tooltip 
                            title="Nome do curso. Cor de referência: cor secundária"
                            arrow
                            placement="top"
                        > */}
                        <div className="flex jcc">
                            <h1
                                className="title"
                                style={{
                                    ...(secondaryColor && {color: secondaryColor}),
                                    userSelect: "none"
                                }}
                            >{courseName}</h1>
                            <Label iconColor={primaryColor} title="Nome do curso. Cor de referência: cor secundária"/>
                        </div>
                        {/* </Tooltip> */}
                        {/* <Tooltip 
                            title="Descrição do curso. Cor de referência: cor da fonte"
                            arrow
                            placement="bottom"
                        > */}
                        <div className="flex jcc">
                            <p
                                style={{
                                    ...(fontColor && {color: fontColor}),
                                    userSelect: "none"
                                }}
                            >{description}</p>
                            <Label iconColor={primaryColor} title="Descrição do curso. Cor de referência: cor da fonte"/>
                        </div>
                        {/* </Tooltip> */}
                    </div>
                    <div id="block5" className="w100 flex jcc" style={{marginBottom: "60px"}} hidden={!description}>
                        <DefaultButton
                            style={{
                                fontSize: "18px"
                            }}
                            text="Fazer Matrícula Agora!"
                            bg={primaryColor}
                            title="Botão. Cor de referência: cor primária"
                        />
                    </div>
                    <div 
                        id="block6" 
                        className="text-center" 
                        style={{
                            padding: "60px",
                            ...(secondaryBackgroundColor && {backgroundColor: secondaryBackgroundColor})
                        }}
                        hidden={!entryDate && !entryPrice && !entryWays}
                    >
                        <h4
                            className="title"
                            style={{
                                ...(secondaryColor && {color: secondaryColor}),
                                marginBottom: "20px"
                            }}
                        >Formas de Ingresso</h4>
                        <div className="flex jcc" hidden={!entryWays}>
                            <p
                                style={{
                                    ...(fontColor && {color: fontColor})
                                }}
                            >{entryWays}</p>
                            <Label iconColor={primaryColor} title="Formas de ingresso. Cor de referência: cor da fonte"/>
                        </div>
                        <div className="flex jcc" hidden={!entryDate}>
                            <p
                                style={{
                                    ...(fontColor && {color: fontColor})
                                }}
                            >{entryDate}</p>
                            <Label iconColor={primaryColor} title="Data de convocação. Cor de referência: cor da fonte"/>
                        </div>
                        <div className="flex jcc" hidden={!entryPrice}>
                            <p
                                style={{
                                    ...(fontColor && {color: fontColor})
                                }}
                            >Inscrição: {entryPrice}</p>
                            <Label iconColor={primaryColor} title="Preço de inscrição. Cor de referência: cor da fonte"/>
                        </div>
                        <DefaultButton
                            style={{
                                fontSize: "18px"
                            }}
                            text="Eu quero me inscrever!"
                            bg={primaryColor}
                            title="Botão. Cor de referência: cor primária"
                        />
                    </div>

                    <div 
                        id="block7" 
                        className="text-center"
                        style={{
                            padding: "60px 0"
                        }}
                    >
                        {/* <Tooltip 
                            title="Certificado, é possível editá-lo na página Geral. Cor de referência: cor de fonte"
                            arrow
                            placement="bottom"
                        > */}
                        <div className="inline-flex">
                            <h2
                                className="title"
                                style={{
                                    ...(secondaryColor && {color: secondaryColor}),
                                    marginBottom: "20px"
                                }}
                            >Certificado</h2>
                            <Label iconColor={primaryColor} title="Certificado, é possível editá-lo na página Geral. Cor de referência: cor de fonte"/>
                        </div>
                        <p
                            style={{
                                ...(fontColor && {color: fontColor}),
                                padding: "0 10em",
                                textAlign: "justify"
                            }}
                        >
                            {generalStyle?.certificate}
                        </p>
                        {/* </Tooltip> */}
                    </div>
                </div>
                <Footer/>
            </div>
        </div>
    );
}

export default PreviewPage;