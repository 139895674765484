import React, { useEffect, useState } from "react";
import LogoHeader from "../LogoHeader";
import TabsContainer from "./TabsContainer";
import InputText from "../Inputs/InputText";

import "./index.css";
import ProfileMenu from "../../ProfileMenu";
import { useSelector } from "react-redux";
import { InputBase, InputAdornment, IconButton, Drawer, List, ListItem, Tabs, Tab, ListItemText } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { AiOutlineSearch } from "react-icons/ai";
import SearchInput from "../Inputs/SearchInput";
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from "react-router";

const useStyles = makeStyles(theme => ({
    root: props => ({
        color: "#ecf0f1",
        backgroundColor: "transparent",
        "& .Mui-selected": {
            color: "white"
        },
        "& .MuiTabs-indicator": {
            backgroundColor: "transparent"
        }
    })
}));

const labels = [
    {
        label: "Home",
        link: "/home"
    },
    {
        label: "Minha Lista",
        link: "/home/minha-lista"
    }
];

const UserHeader = props => {

    const {
        ...other
    } = props;

    const [toggleTopbar, SetToggleTopbar] = useState(false);
    const [drawer, SetDrawer] = useState(false);
    const [tab, SetTab] = useState(0);
    const [tabMount, SetTabMount] = useState(false);

    const generalStyle = useSelector(store => store.AppReducer.generalStyle);

    const history = useHistory();

    const classes = useStyles();

    const HandleScroll = () => {
        let scrollY = window.pageYOffset;
        if (scrollY != 0 && !toggleTopbar) SetToggleTopbar(true);
        else if (scrollY == 0) SetToggleTopbar(false);
    }

    useEffect(() => {
        window.addEventListener("scroll", HandleScroll, { passive: true });
        return () => window.removeEventListener("scroll", HandleScroll);
    }, []);

    useEffect(() => {
        if (tabMount) history.push(labels[tab]?.link);
        SetTabMount(true);
    }, [tab]);

    return (
        <div className="topbar" {...other}>
            <div 
                className="main-header"
                style={{
                    backgroundColor: generalStyle?.primary_color,
                    // backgroundImage: `linear-gradient(to bottom, ${generalStyle?.primary_color} 10%, rgba(0, 0, 0, 0))`
                }}
            >
                <div className="inline-flex flex1" id="left">
                    <IconButton
                        onClick={() => SetDrawer(true)}
                        className="d-lg-none d-block"
                    >
                        <MenuIcon/>
                    </IconButton>
                    <LogoHeader/>
                    {/* <TabsContainer/> */}
                    <div className="tabs d-none d-lg-block">
                        <Tabs
                            value={tab}
                            onChange={(_, value) => {
                                SetTab(value);
                            }}
                            style={{
                                maxWidth: "100vw"
                            }}
                            variant={"scrollable"}
                            scrollButtons={"on"}
                            className={classes.root}
                        >
                            {labels?.map((value, key) => (
                                <Tab label={value?.label} key={key}/>
                            ))}
                        </Tabs>
                    </div>
                </div>
                <div className="inline-flex jcsb align-center secondary-navigation">
                    {/* <InputText
                        variant="filled"
                        label="Pesquisar"
                    /> */}
                    {/* <InputBase
                        className="topbar-searchbar"
                        startAdornment={
                            <InputAdornment position="start">
                                <AiOutlineSearch/>
                            </InputAdornment>
                        }
                    /> */}
                    <SearchInput/>
                    <ProfileMenu/>
                </div>
                <Drawer
                    open={drawer}
                    disableScrollLock
                    onClose={() => SetDrawer(false)}
                >
                    <div
                        style={{
                            padding: "5px",
                            justifyContent: "flex-end"
                        }}
                        className="flex fdrow align-center"
                    >
                        <IconButton 
                            onClick={() => SetDrawer(false)}
                            style={{
                                float: "right"
                            }}
                        >
                            <CloseIcon/>
                        </IconButton>
                    </div>
                    <List style={{ width: "200px" }}>
                        {labels.map((value, key) => (
                            <ListItem 
                                key={key}
                                onClick={() => {
                                    SetTab(key);
                                }}
                                button
                            >
                                <ListItemText
                                    primaryTypographyProps={{
                                        ...(tab === key && {color: "primary"})
                                    }}
                                >
                                    {value?.label}
                                </ListItemText>
                            </ListItem>
                        ))}
                    </List>
                </Drawer>
            </div>
        </div>
    );
}

export default UserHeader;