import { Tabs, Tab } from "@material-ui/core";
import React, { useState } from "react";
import { useHistory } from "react-router";

import TabPanel from "../TabPanel";
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
  root: props => ({
    color: "#ecf0f1",
    backgroundColor: "transparent",
    "& .Mui-selected": {
        color: "white"
    },
    "& .MuiTabs-indicator": {
        backgroundColor: "transparent"
    }
  })
}));

const tabsUrl = ["", "alunos", "minha-lista"];

const TabsContainer = props => {

    const [activeTab, SetActiveTab] = useState(0);

    const generalStyle = useSelector(store => store.AppReducer.generalStyle);
    const classes = useStyles(generalStyle)
    const history = useHistory();

    return (
        <div className="tabs d-none d-lg-block">
            <Tabs
                value={activeTab}
                onChange={(_, value) => {
                    SetActiveTab(value);
                    history.push(`/home/${tabsUrl[value]}`);
                }}
                style={{
                    maxWidth: "100vw"
                }}
                variant={"scrollable"}
                scrollButtons={"on"}
                className={classes.root}
            >
                <Tab label="Home"/>
                <Tab label="Alunos"/>
                <Tab label="Minha Lista"/>
            </Tabs>
        </div>
    );
}

export default TabsContainer;