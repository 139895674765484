import React, { useState } from "react";
import { CardContent } from "@material-ui/core";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import DefaultButton from "../../../../../../components/DefaultButton";
import RestoreIcon from '@material-ui/icons/Restore';

import styles from "./index.module.css";
import { useSelector, useDispatch } from "react-redux";
import { Post } from "../../../../../../utils/request";
import { Show } from "../../../../../../actions/SnackbarActions";
import { useHistory } from "react-router";

const CardDescription = props => {

    const {
        id,
        name,
        description,
        listed,
        GetData,
        OnExpand = () => {}
    } = props;

    const [listLoading, SetListLoading] = useState(false);

    const userId = useSelector(store => store.AppReducer.user.id);

    const dispatch = useDispatch();
    const history = useHistory();

    const AddList = async () => {
        let form = new FormData();
        form.append("course_id", id);
        form.append("user_id", userId);

        SetListLoading(true);
        let response = await Post("user-list-course/create", form);
        SetListLoading(false);
        console.log(response);

        if (response?.status === true) dispatch(Show({
            show: true,
            message: response?.message,
            severity: response?.severity
        }));
        GetData();
    }

    const RemoveList = async () => {
        let form = new FormData();
        form.append("course_id", id);
        form.append("user_id", userId);

        SetListLoading(true);
        let response = await Post("user-list-course/delete", form);
        SetListLoading(false);

        if (response?.status === true) dispatch(Show({
            show: true,
            message: response?.message,
            severity: response?.severity,
            buttonActionText: <span><RestoreIcon/> Desfazer</span>,
            ButtonAction: () => AddList()
        }));
        GetData();
    }

    return (
        <CardContent
            className="card-description"
        >
            <div className="flex fdrow jcsb">
                <div className={styles.mainActions}>
                    <DefaultButton
                        icon={<PlayArrowIcon size={30}/>}
                        style={{
                            padding: 0,
                            borderRadius: "100%",
                            width: "2.2em",
                            height: "2.2em",
                            marginRight: "5px"
                        }}
                        onClick={() => history.push(`/courses/${id}`)}
                    />
                    <DefaultButton
                        icon={listed ? <CheckIcon fontSize="small"/> : <AddIcon fontSize="small"/>}
                        hoverIcon={listed ? <CloseIcon fontSize="small"/> : null}
                        style={{
                            padding: 0,
                            borderRadius: "100%",
                            width: "2.2em",
                            height: "2.2em"
                        }}
                        loading={listLoading}
                        onClick={listed ? RemoveList : AddList}
                        title={listed ? "Remover da sua lista" : "Adicionar a sua lista"}
                    />
                </div>
                <div className="expandable-button">
                    <DefaultButton
                        icon={<ExpandMoreIcon size={30}/>}
                        style={{
                            padding: 0,
                            borderRadius: "100%",
                            width: "2.2em",
                            height: "2.2em"
                        }}
                        onClick={OnExpand}
                    />
                </div>
            </div>
            <div className={styles.description}>
                <p style={{ fontWeight: 700, maxWidth: "100%" }} className="text-truncate">{name}</p>
                <p style={{ maxWidth: "100%" }} className="text-truncate">{description}</p>
            </div>
        </CardContent>
    );
}

export default CardDescription;