import React, { useState } from "react";
import RegisterBody from "./RegisterBody";
import RegisterHeader from "./RegisterHeader";

import { useDispatch, useSelector } from "react-redux";

import "./index.css";
import { useHistory } from "react-router";
import { Post } from "../../utils/request";
import Footer from "../../Home/Footer";
import { PAGE_KEYS, STORAGE_URL } from "../../variables";

const Register = () => {

    const [loading, SetLoading] = useState(false);
    const [errorsList, SetErrors] = useState([]);

    const dispatch = useDispatch();
    const history = useHistory();
    const pageStyle = useSelector(store => store.AppReducer.pagesStyles[PAGE_KEYS.register]);
    const loadingStyle = useSelector(store => store.AppReducer.loading);

    const Register = async (name, email, phone, password) => {
        let form = new FormData();
        form.append("name", name);
        form.append("email", email);
        form.append("phone", phone);
        form.append("password", password);

        SetLoading(true);
        let response = await Post("auth/register", form);

        if (response["success"]) Login(email, password);
        else if (response["errors"]) {
            let errors = Object.values(response["errors"]);
            SetErrors(errors);
            SetLoading(false);
            return;
        }
    }

    const Login = async (email, password) => {
        let form = new FormData();
        form.append("email", email);
        form.append("password", password);

        SetLoading(true);
        let response = await Post("auth/login", form);

        if (response["status"]) {
            let token = response["access_token"];
            let user = response["user"];

            localStorage.setItem("token", token);
            localStorage.setItem("user", JSON.stringify(user));

            dispatch({type: "login", payload: {token: token, user: user}});
        } else if (response["errors"]) {
            let errors = Object.values(response["errors"]);
            SetErrors(errors);
            SetLoading(false);
            return;
        }
        history.push("/home");
    }

    if (loadingStyle) return <Skeleton/>
    return (
        <div 
            className="register-wrapper"
            style={{
                ...(pageStyle && pageStyle["background_image"] && {backgroundColor: pageStyle["background_color"]}),
                ...(pageStyle && pageStyle["background_image"] && {backgroundImage: `url(${STORAGE_URL + pageStyle["background_image"]})`})
            }}
        >
            <RegisterHeader/>
            <RegisterBody
                onSubmit={data => {
                    if (data === null || data === undefined) return;
                    let name = data.name;
                    let email = data.email;
                    let phone = data.phone;
                    let password = data.password;
                    Register(name, email, phone,password);
                }}
                errors={errorsList}
                loading={loading}
            />
            <Footer/>
        </div>
    );
}

const Skeleton = () => {
    return (
        <div>
            <div style={{height: "100px", padding: "5px"}}>
                <div className="skeleton-base skeleton-shimmer" style={{width: "8em", height: "3.25em"}}></div>
            </div>
            <div className="skeleton-base skeleton-shimmer" style={{
                width: "450px",
                height: "30em",
                margin: "auto"
            }}/>
        </div>
    );
}

export default Register;