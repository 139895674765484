import React, { useState } from "react";

import { MdDelete } from "react-icons/md";
import DefaultButton from "../../../components/DefaultButton";
import { Post } from "../../../utils/request";
import { CircularProgress } from "@material-ui/core";
import SweetAlert from "react-bootstrap-sweetalert";

const Board = props => {

    const {
        id = "",
        email = "",
        createdAt = "",
        OnDelete
    } = props;

    const [loading, SetLoading] = useState(false);

    const [popup, SetPopup] = useState(false);

    const Delete = async () => {
        let form = new FormData();
        form.append("id", id);

        SetLoading(true);
        let response = await Post("emails/delete", form);
        SetLoading(false);
        if (response["status"]) OnDelete();
    }

    return (
        <tr id={id}>
            <td>{email}</td>
            <td>{new Date(Date.parse(createdAt)).toLocaleDateString("pt-BR")}</td>
            <td>
                <SweetAlert
                    warning
                    title="Atenção"
                    show={popup}
                    showCancel
                    cancelBtnStyle={{color: "white", textDecoration: "none"}}
                    cancelBtnCssClass="btn-danger"
                    cancelBtnText="Cancelar"
                    confirmBtnText="Excluir"
                    onConfirm={() => {
                        SetPopup(false);
                        Delete();
                    }}
                    onCancel={() => SetPopup(false)}
                >
                    Deseja mesmo excluir o e-mail '{email}' do registro?
                </SweetAlert>
                <DefaultButton
                    onClick={() => SetPopup(true)}
                    width="2.2em"
                    height="2.2em"
                    padding={0}
                    title={`Deletar registro`}
                    bg="danger"
                    loading={loading}
                    icon={!loading && <MdDelete size={17} color="white"/>}
                />
            </td>
        </tr>
    );
}

export default Board;