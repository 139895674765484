import { useState } from "react";
import { Box, Typography, Stack } from "@material-ui/core";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Get } from "../../../utils/request";
import Card from "../CoursesUserView/Card";

import styles from "./index.module.css";

const MyList = () => {

    const [list, SetList] = useState([]);

    const [firstLoading, SetFirstLoading] = useState(false);

    const userId = useSelector(store => store.AppReducer.user.id);

    const GetData = async () => {
        let response = await Get(`user-list-course?userId=${userId}`);
        if (response?.status === true) SetList(response?.list);
        SetFirstLoading(true);
    }

    useEffect(GetData, []);

    if (!firstLoading) return <Skeleton/>
    return (
        <Box>
            <Typography
                variant="h4"
                gutterBottom
            >
                Minha Lista
            </Typography>
            <div className={styles.cardList}>
                {list?.map((value, key) => (
                    <Card
                        id={value?.course?.id}
                        key={key}
                        listed={true}
                        image={value?.course?.image}
                        description={value?.course?.description}
                        lessons={value?.course?.lessons}
                        GetData={GetData}
                    />
                ))}
            </div>
        </Box>
    );
}

const Skeleton = () => (
    <div className="flex fdrow flex-wrap" style={{ margin: "15px" }}>
        <div className="skeleton-base skeleton-shimmer" style={{ width: "25vw", height: "15vw", margin: "10px" }}/>
        <div className="skeleton-base skeleton-shimmer" style={{ width: "25vw", height: "15vw", margin: "10px" }}/>
        <div className="skeleton-base skeleton-shimmer" style={{ width: "25vw", height: "15vw", margin: "10px" }}/>
        <div className="skeleton-base skeleton-shimmer" style={{ width: "25vw", height: "15vw", margin: "10px" }}/>
        <div className="skeleton-base skeleton-shimmer" style={{ width: "25vw", height: "15vw", margin: "10px" }}/>
    </div>
);

export default MyList;