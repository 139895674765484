import { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Post } from "utils/request"
import { Show } from "actions/SnackbarActions"
import FormBody from "./FormBody";

const PostsAdd = () => {
    const [popup, SetPopup] = useState(false);
    const [popupMessage, SetPopupMessage] = useState("");

    const [loading, SetLoading] = useState(false);

    const userId = useSelector(store => store.AppReducer.user?.id);

    const dispatch = useDispatch();
    const history = useHistory();

    const Submit = async (data) => {
        if (!data?.title) {
            SetPopup(true);
            SetPopupMessage("Insira um título!");
            return;
        }

        let form = new FormData();
        form.append("title", data.title);
        form.append("description", data.description);
        form.append("file", data?.file[0]);
        form.append("file_name", data?.fileName);
        SetLoading(true);
        let response = await Post("site-itec/posts/create", form);
        SetLoading(false);
        dispatch(Show({
            show: true,
            message: response?.message,
            severity: response?.severity,
            buttonActionText: "Ver",
            // ButtonAction: () => history.push(`/courses/view/${response?.courseId}`)
        }));

        // if (response?.status === true) history.push("/site-itec/publicacoes");
    }

    return (
        <div className="card">
            <SweetAlert
                warning
                show={popup}
                title="Atenção!"
                confirmBtnText="Ok"
                onConfirm={() => SetPopup(false)}
            >
                {popupMessage}
            </SweetAlert>
            <div className="card-body">
                <h2 className="title">Criar publicação</h2>
                <p>Aqui são criadas as publicações que apareceram no site do Itec</p>
                <br/>
                <FormBody
                    OnConfirm={data => Submit(data)}
                    loading={loading}
                />
            </div>
        </div>
    );
}

export default PostsAdd;