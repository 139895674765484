import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Show } from "actions/SnackbarActions";
import { Post } from "utils/request";
import FormBody from "./FormBody";
import SweetAlert from "react-bootstrap-sweetalert";

const CreateAccount = () => {

    const [popup, SetPopup] = useState(false);
    const [popupMessage, SetPopupMessage] = useState("");

    const [loading, SetLoading] = useState(false);

    const dispatch = useDispatch();
    const history = useHistory();

    const Submit = async (data) => {
        if (!data?.image) {
            SetPopup(true);
            SetPopupMessage("Insira uma imagem");
            return;
        } else if (!data?.name) {
            SetPopup(true);
            SetPopupMessage("Insira um nome!");
            return;
        } else if (!data?.email) {
            SetPopup(true);
            SetPopupMessage("Insira um e-mail!");
            return;
        } 

        let form = new FormData();

        form.append("name", data?.name);
        form.append("email", data?.email);
        form.append("password", data?.password);
        form.append("profile_image", data?.image);
        form.append("phone", data?.phone);
        form.append("role_id", data?.role);
        console.log(data?.image);
        SetLoading(true);
        let response = await Post(`auth/register`, form);
        SetLoading(false);

        dispatch(Show({
            show: true,
            severity: response?.severity,
            message: response?.message
        }));
    }

    return (
        <div className="card">
            <SweetAlert
                warning
                show={popup}
                title="Atenção!"
                confirmBtnText="Ok"
                onConfirm={() => SetPopup(false)}
            >
                {popupMessage}
            </SweetAlert>
            <div className="card-body">
                <h2 className="title">Criar conta</h2>
                <p>Aqui são criados as contas do sistema</p>
                <br/>
                <FormBody
                    OnConfirm={data => Submit(data)}
                    loading={loading}
                />
            </div>
        </div>
    );
}

export default CreateAccount;