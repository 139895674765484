import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LogoHeader from "../../components/LogoHeader";
import { STORAGE_URL } from "../../variables";

import "./RegisterHeader.css";

const RegisterHeader = () => {

    const generalStyle = useSelector(store => store.AppReducer.generalStyle);

    return (
        <div className="register-header">
            <LogoHeader/>
        </div>
    );
}

export default RegisterHeader;