import React, { useState } from "react";

import SweetAlert from "react-bootstrap-sweetalert";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { Post } from "../../../utils/request";
import { Show } from "../../../actions/SnackbarActions";
import FormBody from "./FormBody/index";

const CourseLandingPageConfig = () => {

    const [popup, SetPopup] = useState(false);
    const [popupMessage, SetPopupMessage] = useState("");

    const [loading, SetLoading] = useState(false);

    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();

    const { course_name, course_id } = useParams();

    const Submit = async data => {
        if (!data.title) {
            SetPopup(true);
            SetPopupMessage("Insira um título!");
            return;
        } else if (!data.backgroundColor) {
            SetPopup(true);
            SetPopupMessage("Insira uma cor para o plano de fundo!");
            return;
        } else if (!data.primaryColor) {
            SetPopup(true);
            SetPopupMessage("Insira uma cor primária!");
            return;
        } else if (!data.secondaryColor) {
            SetPopup(true);
            SetPopupMessage("Insira uma cor secundária!");
            return;
        }

        let form = new FormData();
        console.log("---")
        console.log(data);
        form.append("course_id", course_id);
        form.append("title", data?.title);
        form.append("subtitle", data?.subtitle);
        form.append("text", data?.text);
        for (let i = 0; i < data?.cards.length; i++) form.append("card_title[]", data?.cards[i].title);
        for (let i = 0; i < data?.cards.length; i++) form.append("card_text[]", data?.cards[i].text);
        form.append("description", data?.description);
        form.append("entryways", data?.entryWays);
        form.append("entrydate", data?.entryDate);
        form.append("entryprice", data?.entryPrice);
        form.append("font_color", data?.fontColor);
        form.append("icon", data?.icon);
        form.append("background_image", data?.backgroundImage);
        form.append("background_color", data?.backgroundColor);
        form.append("secondary_background_color", data?.secondaryBackgroundColor);
        form.append("primary_color", data?.primaryColor);
        form.append("secondary_color", data?.secondaryColor);
        form.append("video_link", data?.videoLink);
        form.append("catch_phrase", data?.catchPhrase);

        SetLoading(true);
        let response = await Post("styles/courses-lp/update", form);
        SetLoading(false);

        console.log(response);

        if (response["status"] === true) {
            dispatch(Show({
                show: true,
                message: response["message"],
                severity: "success",
                buttonActionText: "Ver",
                ButtonAction: () => history.push(`/nossos-cursos/${course_name}`)
            }));
        }
    }

    return (
        <div className="card">
            <div className="card-body">
                <SweetAlert
                    warning
                    show={popup}
                    title="Atenção!"
                    confirmBtnText="Ok"
                    onConfirm={() => SetPopup(false)}
                >
                    {popupMessage}
                </SweetAlert>
                <h2 className="title">Configurações landing page do curso</h2>
                <p className="text-muted">Aqui é possivel modificar o estilo da landing page do curso</p>
                <hr/>
                <FormBody
                    onConfirm={data => Submit(data)}
                    loading={loading}
                />
            </div>
        </div>
    );
}

export default CourseLandingPageConfig;