import { useState } from "react";
import FormBody from "../FormBody";
import { Post } from "../../../../utils/request";
import { useDispatch} from "react-redux";
import { Show } from "../../../../actions/SnackbarActions";
import SweetAlert from "react-bootstrap-sweetalert";

const HistoryEdit = () => {
  const [popup, SetPopup] = useState(false);
  const [popupMessage, SetPopupMessage] = useState("");

  const [loading, SetLoading] = useState(false);

  const dispatch = useDispatch();

  const Submit = async (data) => {
    if(!data?.title){
      SetPopup(true);
      SetPopupMessage("Insira um título");
      return;
    }else if(!data?.contentHtml){
      SetPopup(true);
      SetPopupMessage("Insira o conteúdo");
      return;
    }
    let form = new FormData();
    form.append("title", data.title);
    form.append("content", data?.contentHtml);
    form.append("type", data.type);

    SetLoading(true);
    let response = await Post("site-itec/institutional/update", form);
    SetLoading(false);
    console.log("alteração: ", response)
    dispatch(Show({
      show: true,
      message: response?.message,
      severity: response?.severity,
      buttonActionText: "Ver"
    }));

  }

  return (
    <div className="card">
      <SweetAlert 
        warning
        show={popup}
        title="Atenção!"
        confirmBtnText="Ok"
        onConfirm={()=> SetPopup(false)}
      >
        {popupMessage}
      </SweetAlert>
      <div className="card-body">
        <h2 className="title"> 
          Editar História
        </h2>
        <p>Aqui é editado o conteúdo da página história mostrada no site do Itec</p>
        <br/>
        <FormBody
          OnConfirm={data => Submit(data)}
          loading={loading}
          type="history"
          pageName="história"
        />
      </div>
    </div>
  )
}
export default HistoryEdit;