import { useEffect } from "react";

const Authentication = () => {

    const Verify = async () => {

    }

    useEffect(() => console.log("teste"), []);

    return null;
}

export default Authentication;