import { CircularProgress, Typography } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { Show } from "actions/SnackbarActions";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Get } from "../../../../utils/request";
import Board from "./Board";

const Table = props => {
    const {
        search = ""
    } = props;

    const [news, SetNews] = useState([]);
    const [loading, SetLoading] = useState(false);

    const [page, SetPage] = useState(1);
    const [maxPage, SetMaxPage] = useState(1);

    const dispatch = useDispatch();

    const GetData = async () => {
        SetLoading(true);
        let response = await Get(`site-itec/news?search=${search}&page=${page}`);
        console.log(response);
        SetLoading(false);

        if (response["status"] === true) {
            SetNews(response?.news);
            SetMaxPage(response?.pagination.last_page);
        } else if (!response) dispatch(Show({
            show: true,
            severity: "warning",
            message: "Falha ao carregar as notícias"
        }));
    }

    useEffect(() => {
        GetData();
    }, [search, page]);

    return (
        <div>
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Título</th>
                            <th>Descrição</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!loading && news?.map((value, index) => (
                            <Board
                                key={index}
                                id={value["id"]}
                                title={value["title"]}
                                description={value["description"]}
                                OnDelete={GetData}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="w100 flex jcc">
                <CircularProgress hidden={!loading}/>
                <Typography
                    hidden={loading || news?.length > 0}
                >
                    Nenhuma notícia encontrada
                </Typography>
            </div>
            <Pagination
                className={(maxPage == 1 ? "hide" : "")}
                style={{display: "flex", justifyContent: "flex-end"}} 
                count={maxPage} 
                value={page}
                onChange={(_, value) => SetPage(value)}
            />
        </div>
    );
}

export default Table;