import React, { useState } from "react";
import FormBody from "./FormBody/index";

import { Post } from "../../../utils/request";

import { Show } from "../../../actions/SnackbarActions";
import { PAGE_KEYS } from "../../../variables";
import { useLocation } from "react-router";
import { useDispatch } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";

const LoginPageConfig = () => {

    const [popup, SetPopup] = useState(false);
    const [popupMessage, SetPopupMessage] = useState("");

    const [loading, SetLoading] = useState(false);

    const location = useLocation();
    const dispatch = useDispatch();

    const Submit = async (data) => {
        console.log(data);

        if (!data.title) {
            SetPopup(true);
            SetPopupMessage("Insira um título!");
            return;
        } else if (!data.backgroundColor) {
            SetPopup(true);
            SetPopupMessage("Insira uma cor para o plano de fundo!");
            return;
        } else if (!data.primaryColor) {
            SetPopup(true);
            SetPopupMessage("Insira uma cor primária!");
            return;
        } else if (!data.secondaryColor) {
            SetPopup(true);
            SetPopupMessage("Insira uma cor secundária!");
            return;
        }

        let form = new FormData();

        form.append("page_key", PAGE_KEYS.login);
        form.append("page_path", location.pathname);
        form.append("title", data.title);
        form.append("icon", data.icon);
        form.append("background_image", data.backgroundImage);
        form.append("background_color", data.backgroundColor);
        form.append("primary_color", data.primaryColor);
        form.append("secondary_color", data.secondaryColor);

        SetLoading(true);
        let response = await Post("styles/pages/update", form);
        SetLoading(false);

        console.log(response);

        if (response["status"] === true) {
            dispatch(Show({
                show: true,
                message: response["message"],
                severity: "success"
            }));
        }
    }

    return (
        <div className="card">
            <div className="card-body">
                <SweetAlert
                    warning
                    show={popup}
                    title="Atenção!"
                    confirmBtnText="Ok"
                    onConfirm={() => SetPopup(false)}
                >
                    {popupMessage}
                </SweetAlert>
                <h2 className="title">Design da página de login</h2>
                <p className="text-muted">Aqui é possivel modificar o estilo da página de login</p>
                <hr/>
                <FormBody
                    onConfirm={data => Submit(data)}
                    loading={loading}
                />
            </div>
        </div>
    );
}

export default LoginPageConfig;