import { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

const SignatureChecker = () => {

    const [popup, SetPopup] = useState(false);

    const user = useSelector(store => store.AppReducer.user);

    const history = useHistory();

    useEffect(() => {
        console.log(user?.role == "Professor" && user?.signature === null);
        (user?.role == "Professor" && user?.signature === null) && SetPopup(true)
    }, []);

    return (
        <SweetAlert
            warning
            title={"Atenção"}
            onConfirm={() => {
                history.push(`/profile/edit/${user?.id}?sig=1`);
                SetPopup(false);
            }}
            onCancel={() => SetPopup(false)}
            show={popup}
            confirmBtnText='Sim'
            cancelBtnText='Não, obrigado'
            confirmBtnBsStyle="primary"
            cancelBtnBsStyle="danger"
            showCancel={true}
        >
            Você não possuí uma assinatura cadastrada no seu perfil, deseja adicionar agora?
        </SweetAlert>
    );
}

export default SignatureChecker;