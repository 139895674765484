import React from "react";
import ProfileBasicInfo from "./ProfileBasicInfo";
import ProfileStatistics from "./ProfileStatistics";

const Profile = () => {

    return (
        <div className="card">
            <div className="card-body">
                <ProfileBasicInfo/>
                <br/>
                <h3 className="title">Estatística</h3>
                <ProfileStatistics/>
            </div>
        </div>
    );
}

export default Profile;