import React, { useEffect, useState } from "react";
import CarouselSlider from "../../../../components/CarouselSlider";
import Card from "../Card";
import { Get } from "../../../../utils/request";
import { useSelector } from "react-redux";

const CarouselGroup = props => {

    const [categories, SetCategories] = useState([]);

    const [firstLoading, SetFirstLoading] = useState(false);

    const userId = useSelector(store => store.AppReducer.user.id);

    const GetData = async () => {
        let response = await Get(`courses-main/category?userId=${userId}`);
        if (response?.status === true) SetCategories(response?.categories);
        SetFirstLoading(true);
    }

    useEffect(() => GetData(), []);

    if (!firstLoading) return <Skeleton/>;
    return (
        <div className="carousel-group">
            {categories.map((category, index) => (
                <CarouselSlider
                    key={index}
                    title={category?.name}
                    swiperProps={{
                        style: {
                            padding: "70px"
                        }
                    }}
                >
                    {category?.courses?.map((course, index2) => (
                        <Card
                            key={index2}
                            id={course?.id}
                            image={course?.image}
                            name={course?.name}
                            description={course?.description}
                            lessons={course?.lessons}
                            listed={course?.listed}
                            GetData={GetData}
                        />
                    ))}
                </CarouselSlider>
            ))}
        </div>
    );
}

const Skeleton = () => (
    <div className="flex fdrow flex-wrap" style={{ margin: "15px" }}>
        <div className="skeleton-base skeleton-shimmer" style={{ width: "25vw", height: "15vw", margin: "10px" }}/>
        <div className="skeleton-base skeleton-shimmer" style={{ width: "25vw", height: "15vw", margin: "10px" }}/>
        <div className="skeleton-base skeleton-shimmer" style={{ width: "25vw", height: "15vw", margin: "10px" }}/>
        <div className="skeleton-base skeleton-shimmer" style={{ width: "25vw", height: "15vw", margin: "10px" }}/>
        <div className="skeleton-base skeleton-shimmer" style={{ width: "25vw", height: "15vw", margin: "10px" }}/>
    </div>
);

export default CarouselGroup;