export const Secs2Time = time => {
    let hours = Math.floor(time / 60 / 60 % 60);
    let minutes = Math.floor(time / 60 % 60);
    let seconds = Math.floor(time % 60);

    return (`${hours}:${minutes}:${seconds}`);
}

export const Secs2Minutes = time => {
    let minutes = Math.ceil(time / 60);

    return minutes;
}