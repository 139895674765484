import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Post } from "../../../utils/request";
import { Show } from "../../../actions/SnackbarActions";
import FormBody from "./FormBody";

const ProfileEditAdmin = () => {

    const [loading, SetLoading] = useState(false);

    const { user_id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const Submit = async data => {

        let form = new FormData();

        form.append("id", user_id);
        form.append("name", data?.name);
        form.append("email", data?.email);
        form.append("password", data?.password);
        form.append("profile_image", data?.image);
        form.append("phone", data?.phone);
        form.append("role_id", data?.role);
        form.append("signature", data?.signature);
        SetLoading(true);
        let response = await Post(`auth/edit_profile`, form);
        SetLoading(false);
        
        dispatch(Show({
            show: true,
            severity: response?.severity,
            message: response?.message
        }));
        
        if (response?.status === true) {
            if (response?.user) localStorage.setItem("user", JSON.stringify(response?.user));
            history.push(`/profile/view/${user_id}`);
        }
    }

    return (
        <div className="profile-edit">
            <div className="card">
                <div className="card-body">
                    <FormBody
                        OnConfirm={Submit}
                        loading={loading}
                    />
                </div>
            </div>
        </div>
    );
}

export default ProfileEditAdmin;