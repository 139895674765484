import React, { useCallback, useEffect, useState } from 'react';
import { URL } from '../variables';
import { useSelector, useDispatch } from 'react-redux';
import Header from '../Header';
import Aside from '../SideBar/Aside';

import '../SideBar/styles/App.scss';
import { login, logout, mudarDados } from '../actions/AppActions';
import { Show } from "../actions/SnackbarActions";
import { Redirect, Route, Switch } from 'react-router';
import EmailsLog from '../pages/LogPages/Emails';
import AccountsLog from '../pages/LogPages/Accounts';
import GeneralConfig from '../pages/ControlPanel/General';
import LandingPageConfig from '../pages/ControlPanel/LandingPage';
import LoginPageConfig from '../pages/ControlPanel/Login';
import RegisterPageConfig from '../pages/ControlPanel/Register';
import Polos from '../pages/Inscricoes/Polos/Polos';
import CriarPolo from '../pages/Inscricoes/Polos/CriarPolo';
import Cursos from '../pages/Inscricoes/Cursos/Cursos';
import CriarCurso from '../pages/Inscricoes/Cursos/CriarCurso';
import EditarCurso from '../pages/Inscricoes/Cursos/EditaCurso';
import EditarPolo from '../pages/Inscricoes/Polos/EditarPolo';
import CourseList from '../pages/Courses/CourseList';
import CoursePage from '../pages/Courses/CoursePage';
import CourseAdd from '../pages/Courses/CourseAdd';
import CourseEdit from '../pages/Courses/CourseEdit';

import { IconButton, Snackbar, Button } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { RiCloseFill } from 'react-icons/ri';
import LessonAdd from '../pages/Courses/CoursePage/CourseLessons/LessonAdd';
import LessonEdit from '../pages/Courses/CoursePage/CourseLessons/LessonEdit';
import UserAdd from '../pages/Courses/CoursePage/CourseUsers/UserAdd';
import RegistrationOptions from '../pages/Inscricoes/Registrations/Registrations';
import Registrations from '../pages/Inscricoes/Registrations/Registrations';
import ViewRegistrations from '../pages/Inscricoes/Registrations/ViewRegistrations';
import Profile from '../pages/Profile';
import ProfileEditAdmin from '../pages/ProfileEdit/Admin';
import UserHeader from '../components/UserHeader';
import CourseLandingPageConfig from '../pages/ControlPanel/CourseLandingPage';
import CoursesUserView from '../pages/Users/CoursesUserView';
import CategoryList from '../pages/Courses/Category/List';
import CategoryAdd from '../pages/Courses/Category/Add';
import CategoryEdit from '../pages/Courses/Category/Edit';
import PreRegisterList from '../pages/Inscricoes/PreRegister/List';
import MyList from '../pages/Users/MyList';
import RegistrationIndex from '../pages/Inscricoes/Registrations/RegistrationIndex';
import NewsAdd from '../pages/ItecSite/News/NewsAdd';
import NewsList from '../pages/ItecSite/News/NewsList';
import NewsEdit from '../pages/ItecSite/News/NewsEdit';
import BannerAdsList from '../pages/ItecSite/BannerAds/BannerAdsList';
import BannerAdsAdd from 'pages/ItecSite/BannerAds/BannerAdsAdd';
import PostsList from 'pages/ItecSite/Posts/PostsList';
import PostsAdd from 'pages/ItecSite/Posts/PostsAdd';
import PostsEdit from 'pages/ItecSite/Posts/PostsEdit';
import BannerAdsEdit from 'pages/ItecSite/BannerAds/BannerAdsEdit';
import RoleBased from 'components/Role/RoleBased';
import RoleBasedStyle from 'components/Role/RoleBasedStyle';
import RoleRoute from 'components/Role/RoleRoute';
import LessonView from 'pages/Courses/CoursePage/CourseLessons/LessonView';
import CourseView from 'pages/Courses/CourseView';
import CreateAccount from 'pages/Users/Create';
import SignatureChecker from 'components/SignatureChecker';
import Authentication from 'components/Authentication';
import TagList from 'pages/Courses/Tags/List';
import TagAdd from 'pages/Courses/Tags/Add';
import TagEdit from 'pages/Courses/Tags/Edit';
import ModuleAdd from 'pages/Courses/CoursePage/Modules/Add';
import ModuleEdit from 'pages/Courses/CoursePage/Modules/Edit';
import ModuleView from 'pages/Courses/CoursePage/Modules/View';
import ExerciseStatistics from 'pages/ExerciseStatistics';
import BannerPrincipalList from 'pages/ItecSite/BannerPrincipal/BannerPrincipalList';
import BannerPrincipalAdd from 'pages/ItecSite/BannerPrincipal/BannerPrincipalAdd';
import BannerPrincipalEdit from 'pages/ItecSite/BannerPrincipal/BannerPrincipalEdit';
import HistoryEdit from 'pages/ItecSite/Institucional/Historico';
import MissionEdit from 'pages/ItecSite/Institucional/Mission';
import VisionEdit from 'pages/ItecSite/Institucional/Vision';
import ValuesEdit from 'pages/ItecSite/Institucional/Values';


const OtherRoute = ({ component: Component, path_name, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            <Redirect to={{ pathname: path_name, state: { from: props.location } }} />
        }
    />
);

const Home = (props) => {
    const toggled = useSelector(store => store.AppReducer.toggled)
    const collapsed = useSelector(store => store.AppReducer.collapsed)
    const token = useSelector(store => store.AppReducer.token)
    const user = useSelector(store => store.AppReducer.user)
    const adminAsUser = useSelector(store => store.AppReducer.adminAsUser);
    
    const snackbar = useSelector(store => store.SnackbarReducer);

    const dispatch = useDispatch();
    const [loading_home, setLoadingHome] = useState(true);
    const [image, setImage] = useState(true);
    // const [toggled, setToggled] = useState(false);

    const handleCollapsedChange = (checked) => {
        dispatch(mudarDados({ collapsed: checked }));

    };

    const handleImageChange = (checked) => {
        setImage(checked);
    };

    const handleToggleSidebar = (value) => {
        // setToggled(value);
        // console.log(value);
        dispatch(mudarDados({ toggled: value }));
    };

    const get_user = useCallback(() => {
        fetch(`${URL}api/auth/user`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                // console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    dispatch(logout());
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.error(erro);
                    setTimeout(() => {
                        get_user();
                    }, 5000);
                }
                else {
                   
                    localStorage.setItem('user', JSON.stringify(resp.user));
                    dispatch(login({ token: token, user: resp.user }));
                    setLoadingHome(false);
                }
            } catch (err) {
                setTimeout(() => {
                    get_user();
                }, 5000);
                console.error(err);
            }

        })
            .catch((err) => {
                setTimeout(() => {
                    get_user();
                }, 5000);
            });
    }, [token])


    useEffect(() => {
        const event = (e) => {
            if (window.innerWidth <= 768) {
                dispatch(mudarDados({ toggled: false, collapsed: false }));

            }
            else {

                dispatch(mudarDados({ toggled: true, collapsed: false }));

            }
        };
        window.addEventListener('resize', event)
        if (window.innerWidth <= 768) {
            dispatch(mudarDados({ toggled: false, collapsed: false }));
        }
        else {
            dispatch(mudarDados({ toggled: true, collapsed: false }));
        }
        get_user();

        return () => {
            window.removeEventListener('resize', event);

            // Anything in here is fired on component unmount.
        }
    }, [])

    const HandlePath = (adminComponent, userPath, allowAdminAsUser = false) => {
        if (user.role == "Admin") {
            if (adminAsUser && allowAdminAsUser) return userPath;
            return adminComponent;
        }
        else return userPath;
    }

    const marginLeft = (toggled == false || window.innerWidth <= 768) ? 0 : (collapsed == false ? 270 : 80);

    return (
        <div className={`app ${toggled ? 'toggled' : ''}`}>
            <Authentication/>
            <RoleBased roles={["Admin", "Professor"]}>
                <Aside
                    image={image}
                    collapsed={collapsed}
                    toggled={toggled}
                    collapsed={collapsed}

                    handleToggleSidebar={handleToggleSidebar}
                    handleCollapsedChange={handleCollapsedChange}
                />
            </RoleBased>
            <RoleBased roles={["Admin", "Professor"]}>
                <Header
                    // image={image}
                    toggled={toggled}
                    collapsed={collapsed}
                    handleToggleSidebar={handleToggleSidebar}
                    handleCollapsedChange={handleCollapsedChange}
                    handleImageChange={handleImageChange}
                />
            </RoleBased>
            <RoleBased roles={["Aluno"]}>
                <UserHeader/>
            </RoleBased>
            <div className="content-page">
                <RoleBasedStyle
                    className="content"
                    defaultStyle={{
                        transition: 'all 0.3s ', paddingBottom: 100, background: '#f8f8fa'
                    }}
                    hasRoleStyle={{
                        marginLeft: marginLeft
                    }}
                    roles={["Admin", "Professor"]}
                >
                    {loading_home == false && <div className="container-fluid">
                        <Switch>
                            <RoleRoute exact path={`${props.match.url}site-itec/institucional/historia`} render={props2 => <HistoryEdit/>}/>
                            <RoleRoute exact path={`${props.match.url}site-itec/institucional/missao`} render={props2 => <MissionEdit/>}/>
                            <RoleRoute exact path={`${props.match.url}site-itec/institucional/visao`} render={props2 => <VisionEdit/>}/>
                            <RoleRoute exact path={`${props.match.url}site-itec/institucional/valores`} render={props2 => <ValuesEdit/>}/>
                            <RoleRoute exact path={`${props.match.url}site-itec/noticias`} render={props2 => <NewsList/>}/>
                            <RoleRoute exact path={`${props.match.url}site-itec/noticias/criar`} render={props2 => <NewsAdd/>}/>
                            <RoleRoute exact path={`${props.match.url}site-itec/noticias/:news_id/editar`} render={props2 => <NewsEdit/>}/>
                            <RoleRoute exact path={`${props.match.url}site-itec/bannerPrincipal-home`} render={props2 => <BannerPrincipalList/>}/>
                            <RoleRoute exact path={`${props.match.url}site-itec/bannerPrincipal-home/:banner_id/editar`} render={props2 => <BannerPrincipalEdit/>}/>
                            <RoleRoute exact path={`${props.match.url}site-itec/bannerPrincipal-home/criar`} render={props2 => <BannerPrincipalAdd/>}/>
                            <RoleRoute exact path={`${props.match.url}site-itec/banner-home`} render={props2 => <BannerAdsList/>}/>
                            <RoleRoute exact path={`${props.match.url}site-itec/banner-home/criar`} render={props2 => <BannerAdsAdd/>}/>
                            <RoleRoute exact path={`${props.match.url}site-itec/banner-home/:banner_id/editar`} render={props2 => <BannerAdsEdit/>}/>

                            <RoleRoute exact path={`${props.match.url}site-itec/publicacoes`} render={props2 => <PostsList/>}/>
                            <RoleRoute exact path={`${props.match.url}site-itec/publicacoes/criar`} render={props2 => <PostsAdd/>}/>
                            <RoleRoute exact path={`${props.match.url}site-itec/publicacoes/:post_id/editar`} render={props2 => <PostsEdit/>}/>

                            <Route exact path={`${props.match.url}home`} render={props2 => <CoursesUserView/>}/>
                            <Route exact path={`${props.match.url}home/minha-lista`} render={props2 => <MyList/>}/>

                            <RoleRoute exact path={`${props.match.url}log/email`} render={props2 => <EmailsLog/>}/>
                            <RoleRoute exact path={`${props.match.url}log/accounts`} render={props2 => <AccountsLog/>}/>
                            <RoleRoute exact path={`${props.match.url}accounts/create`} render={props2 => <CreateAccount/>}/>

                            <RoleRoute exact path={`${props.match.url}polos`} render={props2 => <Polos/>}/>
                            <RoleRoute exact path={`${props.match.url}polos/criar`} render={props2 => <CriarPolo/>}/>
                            <RoleRoute exact path={`${props.match.url}polos/editar/:id`} render={props2 => <EditarPolo/>}/>
                            
                            <RoleRoute exact path={`${props.match.url}cursos`} render={props2 => <Cursos/>}/>
                            <RoleRoute exact path={`${props.match.url}cursos/criar`} render={props2 => <CriarCurso/>}/>
                            <RoleRoute exact path={`${props.match.url}cursos/editar/:id`} render={props2 => <EditarCurso/>}/>
                            <RoleRoute exact path={`${props.match.url}cursos/configuracao-lp/:course_name/:course_id`} render={props2 => <CourseLandingPageConfig/>}/>

                            <RoleRoute exact path={`${props.match.url}inscricoes`} render={props2 => <RegistrationIndex/>}/>
                            <RoleRoute exact path={`${props.match.url}inscricoes/view/:id`} render={props2 => <ViewRegistrations/>}/>
                            <RoleRoute exact path={`${props.match.url}pre-inscricoes/lista`} render={props2 => <PreRegisterList/>}/>

                            <RoleRoute exact path={`${props.match.url}page/config/general`} render={props2 => <GeneralConfig/>}/>
                            <RoleRoute exact path={`${props.match.url}page/config/landing-page`} render={props2 => <LandingPageConfig/>}/>
                            <RoleRoute exact path={`${props.match.url}page/config/login`} render={props2 => <LoginPageConfig/>}/>
                            <RoleRoute exact path={`${props.match.url}page/config/register`} render={props2 => <RegisterPageConfig/>}/>

                            <Route exact path={`${props.match.url}courses/list`} render={props2 => <CourseList/>}/>
                            <RoleRoute exact path={`${props.match.url}courses/add`} render={props2 => <CourseAdd/>} to="/courses/list"/>
                            <RoleRoute exact path={`${props.match.url}courses/edit/:id`} render={props2 => <CourseEdit/>} to="/courses/list"/>
                            <RoleRoute roles={["Admin", "Professor"]} exact path={`${props.match.url}courses/add-user/:course_id`} render={props2 => <UserAdd/>}/>
                            <Route exact path={`${props.match.url}courses/view/:id`} render={props2 => <CoursePage/>}/>
                            <Route exact path={`${props.match.url}courses/exercises-statistics/:course_id/:user_id`} render={() => <ExerciseStatistics/>}/>

                            <Route exact path={`${props.match.url}curso/:course_id/aula/:lesson_id`} render={props2 => <LessonView/>}/>
                            <Route exact path={`${props.match.url}courses/:course_id`} render={props2 => <CourseView/>}/>
                            <Route exact path={`${props.match.url}courses/:course_id/:file_id`} render={props2 => <CourseView/>}/>

                            <RoleRoute roles={["Admin", "Professor"]} exact path={`${props.match.url}modules/:course_id/new`} render={props2 => <ModuleAdd/>}/>
                            <RoleRoute roles={["Admin", "Professor"]} exact path={`${props.match.url}modules/:module_id/:course_id/edit`} render={props2 => <ModuleEdit/>}/>
                            <RoleRoute roles={["Admin", "Professor"]} exact path={`${props.match.url}modules/:module_id/:course_id`} render={props2 => <ModuleView/>}/>
                            <RoleRoute roles={["Admin", "Professor"]} exact path={`${props.match.url}modules/:module_id/:course_id/lesson/new`} render={props2 => <LessonAdd/>}/>
                            <RoleRoute roles={["Admin", "Professor"]} exact path={`${props.match.url}modules/:module_id/:course_id/lesson/:lesson_id/edit`} render={props2 => <LessonEdit/>}/>

                            <RoleRoute exact path={`${props.match.url}categorias`} render={props2 => <CategoryList/>}/>
                            <RoleRoute exact path={`${props.match.url}categorias/novo`} render={props2 => <CategoryAdd/>}/>
                            <RoleRoute exact path={`${props.match.url}categorias/editar/:category_id`} render={props2 => <CategoryEdit/>}/>

                            <RoleRoute exact path={`${props.match.url}tag`} render={props2 => <TagList/>}/>
                            <RoleRoute exact path={`${props.match.url}tag/new`} render={props2 => <TagAdd/>}/>
                            <RoleRoute exact path={`${props.match.url}tag/:tag_id/edit`} render={props2 => <TagEdit/>}/>

                            <Route exact path={`${props.match.url}profile/view/:user_id`} render={props2 => <Profile/>}/>
                            <Route exact path={`${props.match.url}profile/edit/:user_id`} render={props2 => <ProfileEditAdmin/>}/>

                        </Switch>
                    </div>}
                    {loading_home == true &&
                        <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                            <div className="spinner-border text-primary" role="status"  >
                            </div>
                        </div></div>
                    }
                </RoleBasedStyle>
                <SignatureChecker/>
                {/* <Footer/> */}
            </div>
        </div>
    );
}

export default Home;