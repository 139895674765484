import React from "react";
import { BrowserRouter, Route, Switch, Redirect, Router } from "react-router-dom";

import { isAuthenticated } from "./services/auth";
import Home from "./Home/Home";
// import Login from "./Auth/Login";

import LandingPage from "./pages/LandingPage";
import Login from "./pages/Login";
import Register from "./pages/Register";

import { useSelector, useDispatch } from 'react-redux'
import CourseLandingPage from "./pages/Courses/CourseLandingPage";

import { IconButton, Snackbar, Button } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { RiCloseFill } from 'react-icons/ri';
import { Show } from "./actions/SnackbarActions";
import PreRegisterPage from "./pages/PreRegister";

const PrivateRoute = ({ component: Component, ...rest }) => {
    // const stream = useSelector(state => state.AppReducer.stream);
    const token = useSelector(state => state.AppReducer.token);
    return (
        <Route
            {...rest}
            render={props =>
                token != null ? (
                    <Component {...props}>
                    </Component>
                ) : (
                        <Redirect to={{ pathname: '/landing', state: { from: props.location } }} />
                    )
            }
        />)
};

const LoginRoute = ({ component: Component, ...rest }) => {
    const token = useSelector(state => state.AppReducer.token)
    console.warn(rest);
    return (
        <Route
            {...rest}
            render={props =>
                token === null ? (
                    <Component {...props} />
                ) : (
                        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
                    )
            }
        ></Route>
    );
};

const OtherRoute = ({ component: Component, path_name, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            <Redirect to={{ pathname: path_name, state: { from: props.location } }} />
        }
    />
);

const Routes = () => {

    const token = useSelector(state => state.AppReducer.token);
    // const snackbar = useSelector(store => store.SnackbarReducer.show);
    // const dispatch = useDispatch();
    return (
        <BrowserRouter>
            <Switch>
                <LoginRoute exact path="/login" component={(props) => <Login {...props} />} />
                <LoginRoute exact path="/landing" component={(props) => <LandingPage {...props}/>}/>
                <LoginRoute exact path="/register" component={(props) => <Register {...props}/>}/>

                <Route exact path="/nossos-cursos/:course_name" render={props => <CourseLandingPage {...props}/>}/>
                <Route exact path="/pre-inscricao" render={props => <PreRegisterPage/>}/>

                <PrivateRoute path="/" component={(props) => <Home {...props} />} />

                <OtherRoute path="*" path_name='/' />
            </Switch>
        </BrowserRouter>
    );
};

export default Routes;