
import React, { Component, useCallback, useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect } from 'react-router-dom';
import { useHistory } from 'react-router';
import { logout } from './actions/AppActions';
import { connect, useDispatch, useSelector } from 'react-redux';

// users
import user4 from './assets/images/default.png';
import { MdExitToApp } from 'react-icons/md';
import { FaUserAlt } from 'react-icons/fa';
import { URL } from './variables';

const ProfileMenu = (props) => {
    const dispatch = useDispatch();

    const [menu, setMenu] = useState(false);

    const user = useSelector(store=>store.AppReducer.user);

    const history = useHistory();

    const toggle = useCallback(() => setMenu(!menu), [menu]);
    
    const logout_ = useCallback(() => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        dispatch(logout());
    }, []);

    return (
        <React.Fragment>
            <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block" >
                <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
                    <img className="rounded-circle header-profile-user" src={user.imagem==null? user4:URL+user.imagem} height="50" width={'50'} alt="Header Avatar" style={{objectFit:'cover'}} />
                </DropdownToggle>
                <DropdownMenu right>
                    <DropdownItem onClick={() => history.push(`/profile/view/${user?.id}`)} style={{cursor:'pointer',alignItems:'center',display:"flex"}}><FaUserAlt style={{marginRight:5}}/> Perfil</DropdownItem>
                    <DropdownItem tag="a" onClick={logout_} style={{cursor:'pointer',alignItems:'center',display:"flex"}}><MdExitToApp style={{marginRight:5}}/> Sair</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
}

export default withRouter(ProfileMenu);


