import React from "react";
import { Dialog, DialogContent, Modal, IconButton, DialogContentText, Card, CardMedia, CardContent, Typography } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import "./index.css";
import { STORAGE_URL } from "../../../../../variables";

const ExpandableModal = props => {

    const {
        lessons,
        image,
        description,
        OnClose = () => {},
        ...other
    } = props;

    return (
        <Dialog
            {...other}
            onClose={OnClose}
        >
            <DialogContent 
                className="preview-modal" 
                style={{
                    ...(image && {backgroundImage: `url(${STORAGE_URL + image})`}),
                    width: "100%",
                    backgroundSize: "cover"
                }}
            >
                <div style={{float: "right"}}>
                    <IconButton 
                        onClick={OnClose}
                        style={{
                            backgroundColor: "#181818"
                        }}
                    >
                        <CloseIcon style={{ color: "white" }}/>
                    </IconButton>
                </div>
            </DialogContent>
            <DialogContent>
                <div className="preview-description">
                    <p>{description}</p>
                </div>
                <div className="preview-lessons">
                    <Typography
                        align="center"
                        variant="h6"
                        gutterBottom
                        hidden={lessons.length != 0}
                    >
                        Esse curso não possuí nenhuma aula até o momento
                    </Typography>
                    {lessons && lessons?.map((value, i) => (
                        <div className="preview-lesson" key={i}>
                            <h3 key={i} id={value?.title}>{value?.title}</h3>
                            <div className="preview-files">
                                {value?.files?.videos?.map((video, j) => (
                                    <Card
                                        key={`video${j}`}
                                        id={video?.id}
                                    >
                                        <CardMedia
                                            component="video"
                                            alt="card image"
                                            // image="https://occ-0-2063-1740.1.nflxso.net/dnm/api/v6/X194eJsgWBDE2aQbaNdmCXGUP-Y/AAAABSGfgNoxTh845X5kZbKd7wH9fFJzOzPg9Wgj3T_PgInoSOPj9Wt4ui3nCHbBbiSiDTPZbTVBdDm2M-n-uHxDAAlcb-M.webp?r=9f3"
                                            image={video?.path ? STORAGE_URL + video?.path : ""}
                                        />
                                        <CardContent>
                                            {video?.name}
                                        </CardContent>
                                    </Card>
                                ))}
                                {value.files.document?.map((document, j) => (
                                    <Card
                                        key={`video${j}`}
                                        id={document?.id}
                                    >
                                        <CardMedia
                                            component="img"
                                            alt="card image"
                                            image="https://occ-0-2063-1740.1.nflxso.net/dnm/api/v6/X194eJsgWBDE2aQbaNdmCXGUP-Y/AAAABSGfgNoxTh845X5kZbKd7wH9fFJzOzPg9Wgj3T_PgInoSOPj9Wt4ui3nCHbBbiSiDTPZbTVBdDm2M-n-uHxDAAlcb-M.webp?r=9f3"
                                        />
                                        <CardContent>
                                            {document?.name}
                                        </CardContent>
                                    </Card>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default ExpandableModal;